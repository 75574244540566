import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, inject } from '@angular/core';
import { Auth, User, authState } from '@angular/fire/auth';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { Cart, CartSubmitResponse, CartValidationResponse } from 'common-types';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { share, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CartApiService implements OnDestroy {
  private CART_REST_API_SERVER = this.environment.cart_rest_api_server_url;
  private CHECKOUT_REST_API_SERVER =
    this.environment.checkout_rest_api_server_url;

  // private cacheCartsAll: Observable<Event[]> | undefined;
  // private cacheCart: Observable<Event> | undefined;

  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  // user: firebase.User | null = null;
  user: User | null = null;
  subs: Subscription[] = [];

  private auth: Auth = inject(Auth);

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient // private afAuth: AngularFireAuth
  ) {
    // this.user = this.authService.getCurrentUser();
    this.subs.push(
      // this.afAuth.authState.subscribe((user) => {
      authState(this.auth).subscribe((user) => {
        this.user = user;
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  public async apiValidateCart(cart: Cart) {
    console.log('Starting apiValidateCart');
    let cartUrl: string = this.CART_REST_API_SERVER + '/validateCart';
    return firstValueFrom<CartValidationResponse>(
      this.httpClient.post<CartValidationResponse>(cartUrl, cart, {
        ...requireAppCheck,
        headers: this.reqHeader,
      })
    );
  }

  public async apiSubmitCartForOrder(cart: Cart) {
    console.log('Starting apiSubmitCartForOrder');
    let cartUrl: string = this.CART_REST_API_SERVER + '/submitCartForElavon';
    return firstValueFrom<CartSubmitResponse>(
      this.httpClient.post<CartSubmitResponse>(cartUrl, cart, {
        ...requireAppCheck,
        headers: this.reqHeader,
      })
    );
  }

  public apiGetCartByEventIdUserId(
    eventId: string,
    userId: string
  ): Promise<Cart[]> {
    let url: string =
      this.CART_REST_API_SERVER + '/user/' + userId + '/event/' + eventId;

    return firstValueFrom<Cart[]>(
      this.httpClient.get<Cart[]>(url, {
        ...requireAppCheck,
        headers: this.reqHeader,
      })
    );
  }

  public apiGetCartByEventUser(eventId: string): Observable<Cart[]> {
    console.log('GETTING CARTS FROM API BY USER EVENT');
    console.log('USER:' + this.user?.uid);
    console.log('EVENT:' + eventId);

    // /user/:userId/event/:eventId

    return this.httpClient
      .get<Cart[]>(
        this.CART_REST_API_SERVER +
          '/user/' +
          this.user?.uid +
          '/event/' +
          eventId,
        {
          ...requireAppCheck,
          headers: this.reqHeader,
        }
      )
      .pipe(share());
  }

  public apiSendCheckoutNotifications(
    cartId: string,
    isInitialPayment: boolean
  ): Observable<any> {
    const url = `${this.CHECKOUT_REST_API_SERVER}/${cartId}`;
    console.log(`SENDING NOTIFICATIONS BY CART ID, URL: ${url}`);
    return this.httpClient
      .post<any>(url, {
        ...requireAppCheck,
        headers: this.reqHeader,
        isInitialPayment,
      })
      .pipe(share());
  }

  public apiGetCartById(id: string): Observable<Cart> {
    return this.httpClient
      .get<Cart>(this.CART_REST_API_SERVER + '/' + id, {
        ...requireAppCheck,
        headers: this.reqHeader,
      })
      .pipe(share());
  }

  public apiDeleteCart(id: string): Observable<Object> {
    let apiDeleteUrl: string = this.CART_REST_API_SERVER + '/delete/' + id;

    return this.httpClient
      .delete(apiDeleteUrl, {
        ...requireAppCheck,
        headers: this.reqHeader,
      })
      .pipe(
        tap((res: Object) => {
          return res;
        })
      );
  }

  public apiDeleteAllCartsByUser() {
    let apiDeleteUrl: string =
      this.CART_REST_API_SERVER + '/deleteAllByUser/' + this.user?.uid;

    return this.httpClient
      .delete(apiDeleteUrl, {
        ...requireAppCheck,
        headers: this.reqHeader,
      })
      .pipe(
        tap((res) => {
          return res;
        })
      );
  }

  public apiDeleteAllCartsByUserEvent(eventId: string) {
    let apiDeleteUrl: string =
      this.CART_REST_API_SERVER +
      '/deleteAllByUserEvent/user/' +
      this.user?.uid +
      '/event/' +
      eventId;
    return this.httpClient
      .delete(apiDeleteUrl, {
        ...requireAppCheck,
        headers: this.reqHeader,
      })
      .pipe(
        tap((res) => {
          return res;
        })
      );
  }

  public apiAddCart(cart: Cart): Promise<Cart> {
    let apiAddUrl: string = this.CART_REST_API_SERVER;

    return firstValueFrom<Cart>(
      this.httpClient.post<Cart>(apiAddUrl, cart, {
        ...requireAppCheck,
        headers: this.reqHeader,
      })
    );
  }

  public apiCartWakeup() {
    let apiAddUrl: string = this.CART_REST_API_SERVER + '/wakeup';

    return firstValueFrom(
      this.httpClient.get(apiAddUrl, {
        ...requireAppCheck,
        headers: this.reqHeader,
      })
    );
  }

  // public apiUpdateCart(cart: Cart): Observable<Cart | null> {
  //   let apiUpdateUrl: string = this.CART_REST_API_SERVER + '/' + cart.id;

  //   let subject = new Subject<Cart | null>();

  //   cart.userId = this.user?.uid;

  //   console.log('cart to update');
  //   console.log(cart);

  //   if (cart.id !== '1') {
  //     this.httpClient
  //       .put(apiUpdateUrl, cart, {
  //         headers: this.reqHeader,
  //       })
  //       .subscribe((data: any) => {
  //         subject.next(data);
  //       });
  //   }

  //   //cart.saved = true;

  //   return subject.asObservable();
  // }
}
