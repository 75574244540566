import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  inject,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { CartService } from '@jfw-library/ecommerce/core';
import { MeasurementsForm } from '@jfw-library/ecommerce/zod-forms';
import {
  ChestRange,
  //CoatSizeRange,
  HeightRange,
  HipRange,
  NeckRange,
  OutseamRange,
  OverarmRange,
  ShoeSizeRange,
  SleeveRange,
  StomachRange,
  WaistRange,
  WeightRange,
  findClosestMeasurement,
  getLocalStorageMemberId3d,
  removeLocalStorageMemberId3d,
} from 'business-logic';
import {
  EventMember,
  Measurements,
  StyleType
} from 'common-types';
import { Observable, ObservedValueOf, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MeasurementsToolComponent } from '../measurements-tool/measurements-tool.component';

const Ranges: Record<
  keyof Omit<MeasurementsForm, 'derivedFrom' | 'memberId'>,
  any[]
> = {
  height: HeightRange,
  weight: WeightRange,
  //coatSize: CoatSizeRange,
  chest: ChestRange,
  overarm: OverarmRange,
  stomach: StomachRange,
  waist: WaistRange,
  hip: HipRange,
  outseam: OutseamRange,
  neck: NeckRange,
  sleeve: SleeveRange,
  shoeSize: ShoeSizeRange,
};

@Component({
  selector: 'app-member-measurements',
  templateUrl: './member-measurements.component.html',
  styleUrls: ['./member-measurements.component.scss'],
})
export class MemberMeasurementsComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  // @ViewChild('height') heightView!: MatAutocomplete;
  // @ViewChild('weight') weightView!: MatAutocomplete;
  // @ViewChild('chest') chestView!: MatAutocomplete;
  // @ViewChild('overarm') overarmView!: MatAutocomplete;
  // @ViewChild('stomach') stomachView!: MatAutocomplete;
  // @ViewChild('waist') waistView!: MatAutocomplete;
  // @ViewChild('hip') hipView!: MatAutocomplete;
  // @ViewChild('outseam') outseamView!: MatAutocomplete;
  // @ViewChild('neck') neckView!: MatAutocomplete;
  // @ViewChild('sleeve') sleeveView!: MatAutocomplete;
  // @ViewChild('shoeSize') shoeSizeView!: MatAutocomplete;

  @ViewChildren(MeasurementsToolComponent)
  widgets!: QueryList<MeasurementsToolComponent>;

  @Input() member!: EventMember;
  // @Input() event!: Event;
  @Input() reviewMeasurements = false;
  @Input() skipStepOption = true;
  @Input() showToolError = false;
  @Input() submitOption = false;
  @Input() measurementForm!: FormGroup<MeasurementsForm>;
  @Input() measurementsShowError = false;
  @Output() updatedMember = new EventEmitter<EventMember>();
  @Output() emitDerivedFrom = new EventEmitter<EventMember>();
  submitted: boolean = false;
  measurementFormError!: string;
  derivedFrom: string = '';
  threeDLookPublicKey: string = this.environment.threeDLook.publicKey;
  email: string = '';
  memberId: string = '';
  shoeSize: string = '';
  returnUrl: string = '';
  digitalShoeField: boolean = false;
  shoeSizeFormControl = new FormControl('');
  heightFilteredOptions!: Observable<string[]> | undefined;
  weightFilteredOptions!: Observable<number[]> | undefined;
  //coatSizeFilteredOptions!: Observable<string[]> | undefined;
  chestFilteredOptions!: Observable<number[]> | undefined;
  overarmFilteredOptions!: Observable<number[]> | undefined;
  stomachFilteredOptions!: Observable<number[]> | undefined;
  waistFilteredOptions!: Observable<number[]> | undefined;
  hipFilteredOptions!: Observable<number[]> | undefined;
  outseamFilteredOptions!: Observable<number[]> | undefined;
  neckFilteredOptions!: Observable<number[]> | undefined;
  sleeveFilteredOptions!: Observable<number[]> | undefined;
  shoeSizeFilteredOptions!: Observable<string[]> | undefined;
  shoeSizeFieldWarn: boolean = false;
  subscription = new Subscription();
  form = this.measurementForm;

  private document: Document = inject(DOCUMENT);

  constructor(
    @Inject('environment') private environment: any,
    private breakpointObserver: BreakpointObserver,
    private cartService: CartService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('CHANGES: MemberMeasurements');
    console.log(changes);

    if (changes.member) {
      const memberChanges =
        changes.member.currentValue === changes.member.previousValue;
      console.log('MEMBER CHANGES: ' + memberChanges);
    }

    if (changes.measurementForm) {
      const measurementChanges =
        changes.measurementForm.currentValue ===
        changes.measurementForm.previousValue;
      console.log('MEASUREMENT FORM CHANGES: ' + measurementChanges);
    }

    this.initMeasurement();
    setTimeout(() => {
      if (this.form) {
        this.form.markAsUntouched();
        this.form.markAsPristine();
      }
    });
  }

  ngAfterViewInit(): void {
    this.widgets.forEach((widget) => {
      console.log(
        `3DLook widget is defined: email:${widget.email}   returnURL:${widget.returnUrl ?? 'no return url'
        }`
      );

      this.subscription.add(
        widget.measurements$.subscribe((m) => {
          try {
            console.log(
              `3DLook measurement subscription received`,
              this.member.id,
              getLocalStorageMemberId3d()
            );
            if (
              this.member.id !== undefined &&
              this.member.id === getLocalStorageMemberId3d()
            ) {
              console.log(
                `====================INCOMING==============${JSON.stringify(m)}`
              );

              const formData = {
                memberId: this.member.id,
                derivedFrom: m?.derivedFrom ?? '',
                height: m?.height ?? '',
                weight: findClosestMeasurement(WeightRange, m.weight!),
                //coatSize: m?.coatSize ?? null,
                chest: findClosestMeasurement(ChestRange, m.chest!),
                overarm: findClosestMeasurement(OverarmRange, m.overarm!),
                stomach: findClosestMeasurement(StomachRange, m.stomach!),
                waist: findClosestMeasurement(WaistRange, m.waist!),
                hip: findClosestMeasurement(HipRange, m.hip!),
                outseam: findClosestMeasurement(OutseamRange, m.outseam!),
                neck: findClosestMeasurement(NeckRange, m.neck!),
                sleeve: findClosestMeasurement(SleeveRange, m.sleeve!),
                shoeSize: m?.shoeSize ?? this.shoeSizeFormControl.value ?? null,
              };

              console.log(
                `=================OUTGOING=================${JSON.stringify(
                  formData
                )}`
              );
              this.form.setValue(formData);
              removeLocalStorageMemberId3d();
            }
          } catch (error) {
            console.error(error);
          }
        })
      );
    });
  }

  findClosestMeasurement(range: number[], measurement: number): number {
    return findClosestMeasurement(range, measurement);
  }

  closePreviousPanel(
    event: Event,
    prevAutCompletePanelTrigger: MatAutocompleteTrigger
  ) {
    event.stopPropagation();
    prevAutCompletePanelTrigger.closePanel();
  }

  // findClosestNumber(numbers: number[], sample: number): number {
  //   let closestNumber = numbers[0];
  //   let smallestDifference = Math.abs(numbers[0] - sample);

  //   for (let i = 1; i < numbers.length; i++) {
  //     const difference = Math.abs(numbers[i] - sample);
  //     if (difference < smallestDifference) {
  //       smallestDifference = difference;
  //       closestNumber = numbers[i];
  //     }
  //   }

  //   return closestNumber;
  // }

  // findClosestNumber(array: number[], num: number): number {
  //   var i: any = 0;
  //   var minDiff = 1000;
  //   var ans: number = 0;
  //   for (i in array) {
  //     var m = Math.abs(num - array[i]);
  //     if (m < minDiff) {
  //       minDiff = m;
  //       ans = array[i];
  //     }
  //   }
  //   return ans;
  // }

  ngOnInit(): void {
    // this.subscription.add(
    //   this.form.get('shoeSize')?.valueChanges.subscribe(
    //     (value) => {
    //       this.form.get('shoeSize')?.setValue(value, {
    //         onlySelf: true,
    //         emitEvent: false,
    //         emitModelToViewChange: true,
    //       });
    //     },
    //     (error) => {},
    //     () => {}
    //   )
    // );
  }

  initMeasurement(): void {
    this.memberId = this.member.id!;
    this.email = this.member.email!;
    this.setReturnUrl();

    // console.log('INIT: MemberMeasurements');
    this.form = this.measurementForm;
    if (this.member.measurements?.derivedFrom === 'complete' && this.form) {
      this.submitted = true;
      this.derivedFrom = 'complete';
      this.form.disable();
    }
    if (this.form !== undefined) {
      this.derivedFrom = this.member.measurements?.derivedFrom ?? '';
      if (this.hasLocalStorageMeasurements(this.member.id!)) {
        this.setLocalValue(this.member.id!);
      } else if (this.member.id !== undefined) {
        this.form.setValue({
          memberId: this.member.id,
          derivedFrom: this.member.measurements?.derivedFrom ?? '',
          height: this.member.measurements?.height ?? null,
          weight: this.member.measurements?.weight ?? null,
          //coatSize: this.member.measurements?.coatSize ?? null,
          chest: this.member.measurements?.chest ?? null,
          overarm: this.member.measurements?.overarm ?? null,
          stomach: this.member.measurements?.stomach ?? null,
          waist: this.member.measurements?.waist ?? null,
          hip: this.member.measurements?.hip ?? null,
          outseam: this.member.measurements?.outseam ?? null,
          neck: this.member.measurements?.neck ?? null,
          sleeve: this.member.measurements?.sleeve ?? null,
          shoeSize: this.member.measurements?.shoeSize ?? null,
        });

        if (this.hasShoesInLooks()) {
          this.form.controls.shoeSize.setValue('');
        }
      }
    }

    if (this.form) {
      if (this.hasShoesInLooks()) {
        this.shoeSizeFormControl = this.form.controls.shoeSize;
      }

      this.subscription.add(
        this.form.valueChanges.subscribe({
          next: (changes) => this.onUpdate(changes),
        })
      );

      this.heightFilteredOptions = this.getFilteredOptions(
        'height',
        this.member.measurements?.height
      );
      this.weightFilteredOptions = this.getFilteredOptions(
        'weight',
        this.member.measurements?.weight
      );
      //this.coatSizeFilteredOptions = this.getFilteredOptions('coatSize', this.member.measurements?.coatSize);
      this.chestFilteredOptions = this.getFilteredOptions(
        'chest',
        this.member.measurements?.chest
      );
      this.overarmFilteredOptions = this.getFilteredOptions(
        'overarm',
        this.member.measurements?.overarm
      );
      this.stomachFilteredOptions = this.getFilteredOptions(
        'stomach',
        this.member.measurements?.stomach
      );
      this.waistFilteredOptions = this.getFilteredOptions(
        'waist',
        this.member.measurements?.waist
      );
      this.hipFilteredOptions = this.getFilteredOptions(
        'hip',
        this.member.measurements?.hip
      );
      this.outseamFilteredOptions = this.getFilteredOptions(
        'outseam',
        this.member.measurements?.outseam
      );
      this.neckFilteredOptions = this.getFilteredOptions(
        'neck',
        this.member.measurements?.neck
      );
      this.sleeveFilteredOptions = this.getFilteredOptions(
        'sleeve',
        this.member.measurements?.sleeve
      );
      this.shoeSizeFilteredOptions = this.getFilteredOptions(
        'shoeSize',
        this.member.measurements?.shoeSize
      );
    }
  }

  getFilteredOptions(
    controlName: keyof MeasurementsForm,
    initialValue: string | number | null | undefined
  ) {
    const control = this.form.get(controlName);
    if (!control) throw new Error(`Control ${controlName} not found in form`);
    const range =
      Ranges[
      controlName as keyof Omit<typeof Ranges, 'derivedFrom' | 'memberId'>
      ];
    if (!range) throw new Error(`Range for ${controlName} not found`);
    return control.valueChanges.pipe(
      startWith(initialValue),
      map((value: string | number | null | undefined) => {
        if (value === null || value === undefined || value === '') return range;
        const valueIsString = typeof value === 'string';
        const valueIsNumber = typeof value === 'number';
        const options = valueIsString
          ? range.filter((option: string) =>
            option.toLowerCase().startsWith(value ?? '')
          )
          : valueIsNumber
            ? range.filter((option: number) =>
              option.toString().toLowerCase().startsWith(value.toString())
            )
            : [];
        return options.length > 0 ? options : [''];
      })
    );
  }

  private setReturnUrl() {
    // If starting on Mobile, leave returnurl blank as this will allow the user to continue cart on the mobile device.
    // If starting on Desktop, provide the URL to be invoked on mobile when the widget completes.

    const userStartedOnMobile = this.breakpointObserver.isMatched(
      Breakpoints.XSmall
    );

    // The following has the widget go to the event with eventid and memberid settings, but would require a login if
    // first time the user visited the site on the mobile device.
    // this.returnUrl = isMobile ? '' : `${this.environment.threeDLook.returnUrl}/?eventId=${this.event.id}&memberId=${this.memberId}`;

    // This sends user to a landing page.
    this.returnUrl = userStartedOnMobile
      ? ''
      : `${this.environment.threeDLook.returnUrl}`;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isDerivedFrom(derivedFrom: string) {
    // if (this.member.measurements?.derivedFrom === derivedFrom) {
    //   console.log('DerivedFrom:' + derivedFrom);
    //   return true;
    // }

    // return false;
    return this.member.measurements?.derivedFrom === derivedFrom;
  }

  hasShoesInLooks(): boolean {
    let hasShoes =
      this.member.memberLook?.styles.some((style) => {
        return style.styleType === StyleType.Shoes;
      }) ?? false;
    return hasShoes;
  }

  hasLocalStorageMeasurements(id: string): boolean {
    return localStorage.getItem(id) !== null;
  }

  setDerivedFrom(event: any, derived: string): void {
    console.log('SET DERIVED FROM');
    console.log(event);
    console.log(derived);
    const measurements: Measurements = this.member.measurements ?? {};
    if (!this.reviewMeasurements && this.derivedFrom !== derived) {
      this.derivedFrom = derived;
      this.member.measurements = measurements;
      measurements.derivedFrom = this.derivedFrom;
      this.form.controls.derivedFrom.setValue(this.derivedFrom);

      this.emitDerivedFrom.emit(this.member);

      /// Removing this for now. It "sort of" works,
      /// but the new methdology will update the event in the service and get saved
      /// when clicking "Next" or "Prev" (rather than an API call every time)
      // this.updatedMember.emit(this.member);
    }
    setTimeout(() => {
      this.form.markAsUntouched();
      this.form.markAsPristine();
    });
  }

  setSizeFieldWarn(shoeSizeFieldWarn: boolean) {
    this.shoeSizeFieldWarn = shoeSizeFieldWarn;
    this.cartService.updateCheckoutState({
      measurementsShowError: true,
    });
  }

  saveMeasurements(): void {
    if (this.form.status === 'VALID') {
      this.member.measurements = {
        derivedFrom: 'complete',
        height: this.form.controls.height.value ?? undefined,
        weight: this.form.controls.weight.value ?? undefined,
        //coatSize: this.form.controls.coatSize.value ?? undefined,
        chest: this.form.controls.chest.value ?? undefined,
        overarm: this.form.controls.overarm.value ?? undefined,
        stomach: this.form.controls.stomach.value ?? undefined,
        waist: this.form.controls.waist.value ?? undefined,
        hip: this.form.controls.hip.value ?? undefined,
        outseam: this.form.controls.outseam.value ?? undefined,
        neck: this.form.controls.neck.value ?? undefined,
        sleeve: this.form.controls.sleeve.value ?? undefined,
        shoeSize: this.form.controls.shoeSize.value ?? undefined,
      };
      this.submitted = true;
      this.updatedMember.emit(this.member);
      this.removeMeasurementsFromStorage(this.member.id!);
    } else {
      this.form.markAllAsTouched();
      this.measurementFormError =
        'PLEASE FILL OUT THE REQUIRED MEASUREMENT FIELDS.';
    }
  }

  removeMeasurementsFromStorage(id: string): void {
    localStorage.removeItem(id);
  }

  setLocalValue(id: string): void {
    let measurementsFromStorage = localStorage.getItem(id);
    if (measurementsFromStorage && this.form) {
      let measurements: Measurements = JSON.parse(measurementsFromStorage);
      if (this.member.id !== undefined) {
        this.form.setValue({
          memberId: this.member.id,
          derivedFrom: measurements?.derivedFrom ?? null,
          height: measurements?.height ?? null,
          weight: measurements?.weight ?? null,
          //coatSize: measurements?.coatSize ?? null,
          chest: measurements?.chest ?? null,
          overarm: measurements?.overarm ?? null,
          stomach: measurements?.stomach ?? null,
          waist: measurements?.waist ?? null,
          hip: measurements?.hip ?? null,
          outseam: measurements?.outseam ?? null,
          neck: measurements?.neck ?? null,
          sleeve: measurements?.sleeve ?? null,
          shoeSize: measurements?.shoeSize ?? null,
        });
      }
    }
    // this.removeMeasurementsFromStorage(id);
  }

  /**
   * Updates this.member.measurements and stores measurements in localStorage.
   * Properties for form fields that are not valid are stored as undefined in this.member.measurements (and localStorage).
   */
  onUpdate(changes: ObservedValueOf<typeof this.form.valueChanges>): void {
    if (this.submitted === true) {
      return;
    }

    const {
      height,
      weight,
      chest,
      overarm,
      stomach,
      waist,
      hip,
      outseam,
      neck,
      sleeve,
      shoeSize,
    } = this.form.controls;

    this.member.measurements = {
      derivedFrom: this.derivedFrom,
      height: this.isValidMeasurement('height', height.value)
        ? height.value ?? undefined
        : undefined,
      weight: this.isValidMeasurement('weight', weight.value)
        ? weight.value ?? undefined
        : undefined,
      //coatSize: coatSize.valid ? coatSize.value ?? undefined : undefined,
      chest: this.isValidMeasurement('chest', chest.value)
        ? chest.value ?? undefined
        : undefined,
      overarm: this.isValidMeasurement('overarm', overarm.value)
        ? overarm.value ?? undefined
        : undefined,
      stomach: this.isValidMeasurement('stomach', stomach.value)
        ? stomach.value ?? undefined
        : undefined,
      waist: this.isValidMeasurement('waist', waist.value)
        ? waist.value ?? undefined
        : undefined,
      hip: this.isValidMeasurement('hip', hip.value)
        ? hip.value ?? undefined
        : undefined,
      outseam: this.isValidMeasurement('outseam', outseam.value)
        ? outseam.value ?? undefined
        : undefined,
      neck: this.isValidMeasurement('neck', neck.value)
        ? neck.value ?? undefined
        : undefined,
      sleeve: this.isValidMeasurement('sleeve', sleeve.value)
        ? sleeve.value ?? undefined
        : undefined,
      shoeSize: this.isValidMeasurement('shoeSize', shoeSize.value)
        ? shoeSize.value ?? undefined
        : undefined,
    };

    this.storeMeasurements(this.member?.id!, this.member.measurements);
    // this.updatedMember.emit(this.member);
  }

  isValidMeasurement(
    controlName: keyof typeof Ranges,
    value: string | number | null | undefined
  ) {
    if (value === null || value === undefined) return false;
    const range = Ranges[controlName as keyof typeof Ranges];
    const isValid = range.includes(value);
    return isValid;
  }

  onClose(controlName: keyof typeof Ranges): void {
    console.log('onCloseAutocomplete: ', controlName);
    const control = this.form.controls[controlName];
    if (!control) return;
    const value = control.value;
    if (value === null || value === undefined) return;
    const range = Ranges[controlName];
    const isInRange = range.includes(value);
    if (!isInRange) {
      console.log(
        'NOT IN RANGE measurement: ',
        controlName,
        control.value,
        ' - resetting to null'
      );
      control.setValue(null);
    } else {
      console.log('VALID measurement: ', controlName, control.value);
    }
  }

  showMeasurementFormError(): boolean {
    return (
      (this.form &&
        this.form.status === 'INVALID' &&
        this.showToolError &&
        this.measurementsShowError) ||
      (this.shoeSizeFieldWarn &&
        this.hasShoesInLooks() &&
        this.form.controls.shoeSize.value === null) ||
      this.measurementsShowError
    );
  }

  validManualMeasurements(): boolean {
    return this.form.valid;
  }

  storeMeasurements(id: string, measurements: Measurements): void {
    localStorage.setItem(id, JSON.stringify(measurements));
  }

  scrollToSkipStepByMemberId(memberId: string): void {
    let elementToScroll = this.document.getElementsByClassName(memberId)[0];
    setTimeout(() => {
      elementToScroll.scrollIntoView({ behavior: 'smooth', block: 'end' });
    });
  }
}
