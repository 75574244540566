import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CarouselButtonComponent } from './carousel-button/carousel-button.component';
import { CarouselItemElementDirective } from './carousel-item-element/carousel-item-element.directive';
import { CarouselItemDirective } from './carousel-item/carousel-item.directive';
import { CarouselComponent } from './carousel.component';

@NgModule({
  declarations: [
    CarouselButtonComponent,
    CarouselComponent,
    CarouselItemDirective,
    CarouselItemElementDirective,
  ],
  imports: [CommonModule],
  exports: [
    CarouselButtonComponent,
    CarouselComponent,
    CarouselItemDirective,
    CommonModule,
  ],
})
export class CarouselModule {}
