import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewChildren,
  inject,
} from '@angular/core';
import { Subscription, fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CarouselItemElementDirective } from './carousel-item-element/carousel-item-element.directive';
import { CarouselItemDirective } from './carousel-item/carousel-item.directive';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterViewInit, OnDestroy {
  @ContentChildren(CarouselItemDirective)
  public items!: QueryList<CarouselItemDirective>;

  @ViewChildren(CarouselItemElementDirective, { read: ElementRef })
  private itemsElements!: QueryList<ElementRef>;
  private document: Document = inject(DOCUMENT);

  @ViewChild('carousel') private carousel!: ElementRef;

  @Input() ariaLabel = 'Carousel';
  @Input() initialIndex = 0;
  @Input() vertical = false;
  @Input() useButtons = true;
  @Input() mobileDisplayCount: number | undefined;
  @Input() desktopDisplayCount: number | undefined;

  @Input() itemHeight: string | undefined;
  @Input() itemWidth: string | undefined;
  @Input() overrideMobileStyles: boolean = false;
  @Input() itemMargin: string | undefined;

  @Output() newIndex = new EventEmitter();

  private subscription = new Subscription();

  public activeIndex = 0;
  public firstItemVisible = true;
  public lastItemVisible = true;
  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.activeIndex = this.initialIndex;
    this.setDimensionsAndMargins();
    this.scrollToIndex(this.activeIndex, true);

    setTimeout(() => {
      this.cdr.detectChanges();
      this.setIndexAndButtons();

      const scroll$ = fromEvent(this.carousel.nativeElement, 'scroll').pipe(
        debounceTime(100),
        distinctUntilChanged()
      );
      const elementChange$ = this.itemsElements.changes;
      const scrollOrElementChange$ = merge(scroll$, elementChange$);

      this.subscription.add(
        scrollOrElementChange$.subscribe({
          next: () => {
            setTimeout(() => {
              this.setIndexAndButtons();
            });
          },
        })
      );
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('window:resize')
  onResize() {
    setTimeout(() => {
      this.setDimensionsAndMargins();
      this.setIndexAndButtons();
    }, 100);
  }

  private setDimensionsAndMargins(): void {
    if (typeof window !== 'undefined') {
      const isMobile = this.isBrowser && window.innerWidth <= 599.98; // TO-DO: make this injectable
      let displayCount: number | undefined;

      if (isMobile) {
        displayCount = this.mobileDisplayCount;
      } else {
        displayCount = this.desktopDisplayCount;
      }

      if (displayCount !== undefined) {
        if (this.vertical) {
          this.itemHeight = this.calculateDimension(displayCount, isMobile);
        } else {
          this.itemWidth = this.calculateDimension(displayCount, isMobile);
        }
      }

      if (this.itemHeight === undefined) {
        this.itemHeight = 'auto';
      }

      if (this.itemWidth === undefined) {
        this.itemWidth = 'auto';
      }

      if (this.itemMargin === undefined) {
        this.itemMargin = '20px';
      }
    }
  }

  private calculateDimension(displayCount: number, isMobile: boolean): string {
    if (this.overrideMobileStyles) {
      this.itemMargin = '0';
      return '100%';
    }
    if (displayCount === 1 && isMobile) {
      // IPhone CSS override
      this.itemMargin = '3vw';
      return '45vw';
    } else {
      const marginPct = 2.5;
      const totalMarginPct = displayCount * marginPct * 2;
      const dimensionPct = (100 - totalMarginPct) / displayCount;

      this.itemMargin = `${marginPct}%`;

      return `${dimensionPct}%`;
    }
  }

  private isItemVisible(item: HTMLElement): boolean {
    if (this.vertical) {
      return this.isItemVisibleVertical(item);
    } else {
      return this.isItemVisibleHorizontal(item);
    }
  }

  private isItemVisibleVertical(item: HTMLElement): boolean {
    const carouselTop = Math.floor(this.carousel.nativeElement.scrollTop);
    const carouselBottom =
      carouselTop + this.carousel.nativeElement.clientHeight + 1;

    const itemTop = item.offsetTop;
    const itemBottom = itemTop + item.clientHeight;

    const isTotal = itemTop >= carouselTop && itemBottom <= carouselBottom;
    return isTotal;
  }

  private isItemVisibleHorizontal(item: HTMLElement): boolean {
    const carouselLeft = Math.floor(this.carousel.nativeElement.scrollLeft);
    const carouselRight =
      carouselLeft + this.carousel.nativeElement.clientWidth + 1;
    const itemLeft = item.offsetLeft;
    const itemRight = itemLeft + item.clientWidth;

    const isTotal = itemLeft >= carouselLeft && itemRight <= carouselRight;
    return isTotal;
  }

  private setIndexAndButtons(): void {
    if (this.itemsElements.length <= 1) {
      this.firstItemVisible = true;
      this.lastItemVisible = true;
      this.cdr.detectChanges();
      return;
    }

    const elementsInView: ElementRef[] = [];
    const firstCarouselIndex = parseInt(
      this.itemsElements.first.nativeElement.getAttribute('data-carousel-index')
    );
    const lastCarouselIndex = parseInt(
      this.itemsElements.last.nativeElement.getAttribute('data-carousel-index')
    );
    let includesFirst = false;
    let includesLast = false;

    for (let itemElement of this.itemsElements) {
      const inViewport = this.isItemVisible(itemElement.nativeElement);
      if (inViewport) {
        elementsInView.push(itemElement);
        const currentCarouselIndex = parseInt(
          itemElement.nativeElement.getAttribute('data-carousel-index')
        );
        if (currentCarouselIndex === firstCarouselIndex) {
          includesFirst = true;
        }
        if (currentCarouselIndex === lastCarouselIndex) {
          includesLast = true;
        }
      }
    }

    if (elementsInView.length === 0) {
      return;
    }

    const currentElement = elementsInView[0];
    const carouselIndex = parseInt(
      currentElement.nativeElement.getAttribute('data-carousel-index')
    );

    this.activeIndex = carouselIndex;
    this.firstItemVisible = includesFirst;
    this.lastItemVisible = includesLast;
    this.cdr.detectChanges();
    this.newIndex.emit(this.activeIndex);
  }

  private scrollToElement(
    element: HTMLElement | null,
    behavior = 'smooth'
  ): void {
    if (element) {
      this.cdr.detectChanges();
      if (this.vertical) {
        this.carousel.nativeElement.scrollTo({
          top: element.offsetTop,
          behavior: behavior,
        });
      } else {
        this.carousel.nativeElement.scrollTo({
          left: element.offsetLeft,
          behavior: behavior,
        });
      }
    }
  }

  public scrollToIndex(index: number, afterViewInit = false): void {
    if (typeof document !== 'undefined') {
      const element: HTMLElement | null = this.document.querySelector(
        `[data-carousel-index='${index}']`
      );
      const behavior = afterViewInit ? 'instant' : 'smooth';
      this.scrollToElement(element, behavior);
    }
  }

  public scrollPrev(): void {
    const prevElement: HTMLElement | null = this.document.querySelector(
      `[data-carousel-index='${this.activeIndex - 1}']`
    );
    this.scrollToElement(prevElement);
  }

  public scrollNext(): void {
    const nextElement: HTMLElement | null = this.document.querySelector(
      `[data-carousel-index='${this.activeIndex + 1}']`
    );
    this.scrollToElement(nextElement);
  }
}
