<ng-container *ngIf="!loading; else loadingSpinner">
  <div class="container">
    <h1 class="event-details-header" *ngIf="!event">Event Details</h1>
    <div class="event-name-header-container">
      <h2 [ngClass]="eventFormWarning || eventDateWarn ? 'warn' : ''">
        Event Name
      </h2>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="event-name-field-container">
        <div class="form-warning-container">
          <div
            *ngIf="eventFormWarning === 'required'"
            [ngClass]="
              eventFormWarning ? 'required-text warn' : 'required-text'
            "
          >
            * Required
          </div>
        </div>

        <div class="grid">
          <mat-form-field>
            <mat-label>Event Name</mat-label>
            <mat-error
              *ngIf="getFirstSchemaFieldError(form.controls.eventName) as error"
              >{{ error }}</mat-error
            >
            <input
              required
              matInput
              formControlName="eventName"
              [maxLength]="eventNameMaxLength"
            />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Event Type</mat-label>
            <mat-error
              *ngIf="getFirstSchemaFieldError(form.controls.eventType) as error"
              >{{ error }}</mat-error
            >
            <mat-select required formControlName="eventType">
              <mat-option
                *ngFor="let eventType of eventTypeOptions"
                [value]="eventType"
                >{{ eventType }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <app-date-form
            (formReady)="addChildForm('eventDate', $event)"
            (required)="(true)"
            label="Event Date"
            [initialValue]="event ? event.eventDate : 0"
            [disabled]="isSaving || isAccepted"
            (valueChange)="setDate($event)"
            [error]="
              eventDateWarn
                ? 'Events cannot be created less than three weeks from event date.'
                : ''
            "
            [hideRequiredMarker]="false"
            [maxDate]="maxDate"
          >
          </app-date-form>
        </div>
      </div>

      <div class="event-organizer-header-container">
        <h2 [ngClass]="organizerFormWarning ? 'warn' : ''">
          Event Organizer Details
        </h2>
      </div>

      <div class="event-organizer-field-container">
        <div class="form-warning-container">
          <div
            *ngIf="organizerFormWarning === 'required'"
            [ngClass]="
              organizerFormWarning === 'required'
                ? 'required-text warn'
                : 'required-text'
            "
          >
            * Required
          </div>
        </div>

        <div class="grid">
          <mat-form-field>
            <mat-label>First Name</mat-label>
            <mat-error
              *ngIf="
                getFirstSchemaFieldError(
                  form.controls.organizerFirstName
                ) as error
              "
              >{{ error }}</mat-error
            >
            <input
              matInput
              formControlName="organizerFirstName"
              required
              [maxLength]="organizerNameMaxLength"
            />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Last Name</mat-label>
            <mat-error
              *ngIf="
                getFirstSchemaFieldError(
                  form.controls.organizerLastName
                ) as error
              "
              >{{ error }}</mat-error
            >
            <input
              matInput
              formControlName="organizerLastName"
              required
              [maxLength]="organizerNameMaxLength"
            />
          </mat-form-field>
          <mat-form-field>
            <mat-label>E-Mail</mat-label>
            <mat-error
              *ngIf="
                getFirstSchemaFieldError(form.controls.organizerEmail) as error
              "
              >{{ error }}</mat-error
            >
            <input
              matInput
              formControlName="organizerEmail"
              required
              [maxLength]="emailMaxLength"
            />
            <mat-error
              *ngIf="
                form.controls.organizerEmail.hasError('organizerEmailMatch')
              "
              >Email must be different than Dealer email</mat-error
            >
          </mat-form-field>
          <app-phone-form
            (formReady)="addChildForm('organizerPhone', $event)"
            (valueChange)="setPhone($event)"
            [initialValue]="event ? organizerPhone : ''"
            [disabled]="isSaving || isAccepted"
          ></app-phone-form>
        </div>
      </div>
      <div class="form-button-container">
        <span *ngIf="eventFormWarning" class="warn"
          >PLEASE FILL OUT THE REQUIRED FIELDS</span
        >
        <button
          mat-flat-button
          [color]="eventFormWarning ? 'warn' : 'primary'"
          type="submit"
          [disabled]="isSaving || form.invalid"
          style="display: inline-flex"
        >
          <ng-container *ngIf="!isSaving; else savingSpinner">
            {{ event ? "Next" : "Create Event" }}
          </ng-container>
          <ng-template #savingSpinner>
            <!-- <mat-spinner diameter="20"></mat-spinner> -->
            <mat-spinner diameter="20" strokeWidth="2"> </mat-spinner>
          </ng-template>
        </button>
      </div>

      <!-- <ng-template #elseBlock>
        <div class="form-button-container">
          <mat-spinner></mat-spinner>
        </div>
      </ng-template> -->
    </form>
  </div>
</ng-container>

<ng-template #loadingSpinner>
  <mat-spinner
    style="margin-left: auto; margin-right: auto; margin-top: calc(33% - 50px)"
  ></mat-spinner>
</ng-template>
