import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MeasurementsForm } from '@jfw-library/ecommerce/zod-forms';

@Component({
  selector: 'app-view-measurements',
  templateUrl: './view-measurements.component.html',
  styleUrls: ['./view-measurements.component.scss'],
})
export class ViewMeasurementsComponent implements OnInit {
  @Input() measurementForm!: FormGroup<MeasurementsForm>;
  form!: FormGroup<MeasurementsForm>;

  constructor(@Inject('environment') private environment: any) {}

  ngOnInit(): void {
    this.form = this.measurementForm;
    this.form.disable();
  }
}
