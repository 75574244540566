import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CarouselComponent } from '@jfw-library/ecommerce/common-components/carousel';
import { EcomStyle, MannequinAccessoryImageType } from 'common-types';

@Component({
  selector: 'app-look-mannequin-accessories',
  templateUrl: './look-mannequin-accessories.component.html',
  styleUrls: ['./look-mannequin-accessories.component.scss'],
})
export class LookMannequinAccessoriesComponent implements OnChanges {
  @ViewChild('carousel') carousel: CarouselComponent | undefined;

  @Input() styles: EcomStyle[] | undefined;
  @Input() vertical = false;
  @Input() useButtons = false;

  previousStyles: EcomStyle[] | undefined;
  mannequinAccessoryImageTypes = Object.values(
    MannequinAccessoryImageType
  ) as string[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.styles !== undefined) {
      this.previousStyles = changes.styles.previousValue;
    }
  }

  public accessoriesCount(): number {
    const accessories = this.styles?.filter((style) => {
      if (
        this.mannequinAccessoryImageTypes.includes(style.styleType as string)
      ) {
        return style;
      }
      return;
    });
    if (accessories) {
      return accessories.length;
    }
    return 0;
  }

  public getAnimation(): string {
    return true ? 'animate' : 'noanimate';
  }

  getImage(imageUrl: string): string {
    let newUrl: string = imageUrl.trim();

    newUrl = newUrl.replace(
      /https:\/\/www.jimsformalwear.com\/images\/1280x1920\//g,
      ''
    );

    newUrl = newUrl.replace(
      /https:\/\/www.jimsformalwear.com\/images\/415x623\//g,
      ''
    );

    // newUrl = newUrl.replace(
    //   /https:\/\/www.jimsformalwear.com\/images\/undefinedxNaN\//g,
    //   ''
    // );

    newUrl = newUrl.replace(/https:\/\/www.jimsformalwear.com\/\_\/img\//g, '');

    newUrl = newUrl.replace(/assets\/product-images\//g, '');

    return newUrl;
  }
}
