<div class="manual-measurements-radio-container">
  <form
    class="manual-measurements-form"
    [formGroup]="form"
    disabled="true"
  >
    <mat-form-field appearance="outline">
      <mat-label>Height</mat-label>
      <input
        type="text"
        placeholder="Height"
        aria-label="Height"
        matInput
        formControlName="height"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Weight (Lbs)</mat-label>
      <input
        type="text"
        placeholder="Weight (Lbs)"
        aria-label="Weight"
        matInput
        formControlName="weight"
      />
    </mat-form-field>
<!--
    <mat-form-field appearance="outline">
      <mat-label>Coat Size</mat-label>
      <input
        type="text"
        placeholder="Coat Size"
        aria-label="Coat Size"
        matInput
        formControlName="coatSize"
      />
    </mat-form-field>
-->
    <mat-form-field appearance="outline">
      <mat-label>Chest</mat-label>
      <input
        type="text"
        placeholder="Chest"
        aria-label="Chest"
        matInput
        formControlName="chest"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Overarm</mat-label>
      <input
        type="text"
        placeholder="Overarm"
        aria-label="Overarm"
        matInput
        formControlName="overarm"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Stomach</mat-label>
      <input
        type="text"
        placeholder="Stomach"
        aria-label="Stomach"
        matInput
        formControlName="stomach"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Waist</mat-label>
      <input
        type="text"
        placeholder="Waist"
        aria-label="Waist"
        matInput
        formControlName="waist"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Hip</mat-label>
      <input
        type="text"
        placeholder="Hip"
        aria-label="Hip"
        matInput
        formControlName="hip"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Outseam</mat-label>
      <input
        type="text"
        placeholder="Outseam"
        aria-label="Outseam"
        matInput
        formControlName="outseam"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Neck</mat-label>
      <input
        type="text"
        placeholder="Neck"
        aria-label="Neck"
        matInput
        formControlName="neck"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Sleeve</mat-label>
      <input
        type="text"
        placeholder="Sleeve"
        aria-label="Sleeve"
        matInput
        formControlName="sleeve"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Shoe Size</mat-label>
      <input
        type="text"
        placeholder="Shoe Size"
        aria-label="Shoe Size"
        matInput
        formControlName="shoeSize"
      />
    </mat-form-field>
  </form>
</div>
