import { Pipe, PipeTransform } from '@angular/core';
import { Image } from 'common-types';

@Pipe({
  name: 'mannequinImage',
})
export class MannequinImagePipe implements PipeTransform {
  transform(images: Image[], mannequinImageType: string): Image[] {
    let result: Image[] = [];

    if (!images) {
      return result;
    }
    if (!mannequinImageType) {
      return result;
    }

    mannequinImageType = mannequinImageType.toLowerCase();

    return images.filter((image) => {
      if (image && image.mannequinImage) {
        return image.mannequinImage.toLowerCase() === mannequinImageType;
      }
      return false;
    });
  }
}
