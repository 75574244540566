import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RECAPTCHA_BASE_URL } from './tokens';
import { ReCaptchaCheckboxService } from './recaptcha-checkbox.service';

@NgModule({
  declarations: [],
  imports: [ CommonModule ],
  exports: [],
  providers: [
    ReCaptchaCheckboxService,
    // {provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaV3Key},
    // {provide: RECAPTCHA_CHECKBOX_SITE_KEY, useValue: environment.recaptchaCheckboxKey},
    {provide: RECAPTCHA_BASE_URL, useValue: 'https://www.google.com/recaptcha/enterprise.js'},
  ],
})
export class ReCaptchaCheckboxModule {}
