<div
  (click)="shoeValidCheck()"
>
  <div
    [ngClass]="shoeFieldRequired && shoeSize === '' ? 'saia-widget-container-disabled' : ''"
  >
    <div
      class="saia-widget-container-{{index}}"
      (click)="setLocalStorageMemberId3d()"
      disabled="true"
    ></div>
  </div>
</div>
