import { Component, Input, OnInit, inject } from '@angular/core';
import { Event, EventTimeline } from 'common-types';
import { Observable, delay } from 'rxjs';
import { EcommerceSharedModuleEventService } from '../../services/event/shared/ecommerce-shared-module-event.service';

@Component({
  selector: 'app-event-timeline',
  templateUrl: './event-timeline.component.html',
  styleUrls: ['./event-timeline.component.scss'],
})
export class EventTimelineComponent implements OnInit {

  private _event: Event | undefined;

  @Input()
  set event(incomingEvent: Event | undefined) {
    if (!incomingEvent) return;
    if (this._event?.eventDate === incomingEvent.eventDate) return;
    this._event = incomingEvent;
    console.log("getting event timeline")
    this.eventTimeline$ = this.ecommerceSharedModuleEventService.getEventTimeline(
      incomingEvent.eventDate
    ).pipe(
      delay(3000)
    )
  };

  get event() {
    return this._event;
  }


  eventTimeline$: Observable<EventTimeline> | undefined;

  private ecommerceSharedModuleEventService = inject(EcommerceSharedModuleEventService);

  constructor() { }

  ngOnInit(): void {
  }
}
