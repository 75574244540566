import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-carousel-button',
  templateUrl: './carousel-button.component.html',
  styleUrls: ['./carousel-button.component.scss'],
})
export class CarouselButtonComponent {
  @Input() direction!: 'up' | 'down' | 'left' | 'right';
  @Input() disabled!: boolean;
  @Input() hidden = false;
  @Input() ariaLabel!: string;
  @Output() clickEvent = new EventEmitter<void>();

  public onClick(): void {
    this.clickEvent.emit();
  }
}
