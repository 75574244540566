import { Component, OnInit, inject } from '@angular/core';
import { Auth, User, authState } from '@angular/fire/auth';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EventService } from '@jfw-library/ecommerce/core';
import {
  DealerEventDetailsFormControls,
  DealerEventDetailsFormSchema,
  RequiredAdminSchema,
  ZodFormUtilities,
} from '@jfw-library/ecommerce/zod-forms';
import {
  convertUnixTimestampToDate,
  getMaxDateForEvent,
  getNameFromFirstAndLastName,
  getOnlineLeadTimeDays,
  getOrganizerFromEvent,
} from 'business-logic';
import {
  DealerInfo,
  DealerUserEvent,
  Event,
  EventAdmin,
  EventAdminNoId,
  EventNameSchema,
  EventType,
  JFWNewEmailSchema,
} from 'common-types';
import dayjs from 'dayjs';
import { Subscription, from, map, switchMap, tap } from 'rxjs';
import { DealerEventService } from '../../services/dealer-event/dealer-event.service';
import { EcommerceSharedModuleEventService } from '../../services/event/shared/ecommerce-shared-module-event.service';

const Dealer_Portal_Event_Type_Options = [
  EventType.Wedding,
  EventType.Quinceañera,
  EventType.Prom,
  EventType.SpecialEvent,
];
type DealerPortalEventTypeOptions =
  (typeof Dealer_Portal_Event_Type_Options)[number];

// interface RegisterNewEventForm {
//   eventName: FormControl<string>;
//   eventType: FormControl<DealerPortalEventTypeOptions | ''>;
//   eventDate?: ObservedValueOf<DateFormComponent['formReady']>;
//   organizerFirstName: FormControl<string>;
//   organizerLastName: FormControl<string>;
//   // organizerRole: FormControl<string>;
//   organizerPhone?: ObservedValueOf<PhoneFormComponent['formReady']>;
//   organizerEmail: FormControl<string>;
// }

@Component({
  selector: 'app-dealer-event-details',
  templateUrl: './dealer-event-details.component.html',
  styleUrls: ['./dealer-event-details.component.scss'],
})
export class DealerEventDetailsComponent implements OnInit {
  loading = true;
  editMode = false;
  dealerUserEvent: DealerUserEvent | undefined;
  event: Event | undefined;
  eventDate = 0;
  organizerPhone = '';
  // user: firebase.User | null = null;
  user: User | null = null;
  subscription = new Subscription();
  registeringEvent: boolean = false;
  eventFormWarning: 'required' | 'other' | false = false;
  organizerFormWarning: 'required' | 'email' | '' = '';
  organizerEmailMatchWarn: boolean = false;
  dealerIds: string[] = [];
  isAccepted = false;
  isSaving: boolean = false;
  eventDateWarn: boolean = false;
  maxDate!: Date;
  readonly eventNameMaxLength = EventNameSchema.maxLength ?? 50;
  readonly organizerNameMaxLength =
    RequiredAdminSchema.shape.firstName.maxLength ?? 30;
  readonly emailMaxLength = JFWNewEmailSchema.maxLength ?? 50;
  eventTypeOptions = Dealer_Portal_Event_Type_Options;

  form = new FormGroup<DealerEventDetailsFormControls>(
    {
      eventName: new FormControl('', {
        nonNullable: true,
        // validators: Validators.required,
      }),
      eventType: new FormControl('', {
        nonNullable: true,
        // validators: Validators.required,
      }),
      organizerFirstName: new FormControl('', {
        nonNullable: true,
        // validators: Validators.required,
      }),
      organizerLastName: new FormControl('', {
        nonNullable: true,
        // validators: Validators.required,
      }),
      organizerEmail: new FormControl('', {
        nonNullable: true,
        validators: [
          // Validators.required,
          // JfwEmailValidator.isValid(),
          this.organizerEmailValidator(),
        ],
      }),
    },
    {
      validators: [
        ZodFormUtilities.zodFormGroupValidator(DealerEventDetailsFormSchema),
      ],
    }
  );

  private auth: Auth = inject(Auth);

  constructor(
    private eventService: EventService,
    // private afAuth: AngularFireAuth,
    private dealerEventService: DealerEventService,
    private route: ActivatedRoute,
    private ecomSharedModuleService: EcommerceSharedModuleEventService
  ) { }

  getFirstSchemaFieldError = ZodFormUtilities.getFirstSchemaFieldErrorMessage;

  ngOnInit(): void {
    // check to see if this is in the event manager module (edit)
    // or dealer portal app module (create)
    this.maxDate = getMaxDateForEvent();

    this.subscription.add(
      authState(this.auth).subscribe((user) => {
        this.user = user;
        if (!this.user) return;
        this.dealerEventService
          .getDealerUserDealerIds(this.user.uid)
          .subscribe((ids) => {
            this.dealerIds = ids;
          });
      })
    );

    this.subscription.add(
      this.form.valueChanges.subscribe((val) => {
        if (this.form.invalid) {
          console.log('form invalid');
          this.setEventFormWarning();
          this.setOrganizerFormWarning();
        } else {
          this.eventFormWarning = false;
          this.organizerFormWarning = '';
        }
      })
    );

    const route = this.route.snapshot.url[0].path;
    if (route === 'details') {
      console.log('Edit mode enabled');
      this.editMode = true;
      this.subscription.add(
        this.eventService.selectedEvent$
          .pipe(
            tap((event) => {
              console.log('Event received from selectedEvent$', event);
              this.loading = true;
              this.event = event;
            }),
            switchMap((event) =>
              from(this.initializeEventDetails(event, this.editMode)).pipe(
                map(() => event),
                tap(() => (this.loading = false))
              )
            )
          )
          .subscribe()
      );
    } else {
      console.log('Create mode enabled');
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async initializeEventDetails(event: Event | undefined, editMode: boolean) {
    if (editMode && event) {
      const dealerUserEvent =
        await this.dealerEventService.getDealerUserEventById(event.id);
      this.dealerUserEvent = dealerUserEvent;
      this.initializeForm(event);

      const { acceptedDate } = dealerUserEvent;

      const isAccepted = acceptedDate !== undefined && acceptedDate !== 0;
      this.isAccepted = isAccepted;

      if (isAccepted) {
        console.log('Event is accepted.  Disabling form.');
        this.form.disable({ emitEvent: false });
      } else {
        console.log('Event is not accepted.  Enabling form.');
        this.form.enable({ emitEvent: false });
      }
    }
  }

  /** Dealer Portal currently only supports Weddings and Quinceañeras.
   * This function checks if the event type is one of those supported types.
   * If not, it will throw an error.
   * This function is used to validate the event type before initializing the form.
   */
  private isDealerPortalEventTypeOptions(
    value: EventType
  ): value is DealerPortalEventTypeOptions {
    return this.eventTypeOptions.some((type) => type === value);
  }

  initializeForm(event: Event): void {
    console.log('Initializing Form');
    const organizer = getOrganizerFromEvent(event);
    const firstName = organizer?.firstName;
    const lastName = organizer?.lastName;

    this.setDate(event.eventDate);
    this.setPhone(organizer?.phone);
    this.organizerPhone = organizer?.phone ?? '';
    this.form.controls.eventName.setValue(event.eventName);

    if (!this.isDealerPortalEventTypeOptions(event.eventType)) {
      console.error(
        `The event type ${event.eventType} is not supported in the Dealer Portal.`
      );
      alert(
        'This event type is not supported in the Dealer Portal.  Please select a different event type.'
      );
      throw new Error(`Unsupported event type: ${event.eventType}`);
    }
    this.form.patchValue({
      eventType: event.eventType,
      organizerFirstName: firstName ?? '',
      organizerLastName: lastName ?? '',
      organizerEmail: organizer?.email ?? '',
    });
    // this.form.controls.eventType.setValue(event.eventType);
    // this.form.controls.organizerFirstName.setValue(firstName ?? '');
    // this.form.controls.organizerLastName.setValue(lastName ?? '');
    // this.form.controls.organizerEmail.setValue(organizer?.email ?? '');
  }

  addChildForm<key extends keyof DealerEventDetailsFormControls>(
    name: key,
    // group: Exclude<RegisterNewEventFormControls[key], undefined>
    control: AbstractControl<any, any>
  ) {
    this.form.setControl(name, control as any);
  }

  organizerEmailValidator(): any {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email = control.value;
      if (email === this.user?.email) {
        return { organizerEmailMatch: true };
      }

      return null;
    };
  }

  setDate(eventDate: number | undefined): void {
    if (eventDate !== undefined) {
      this.eventDate = eventDate;
      if (this.eventDate !== 0) {
        this.eventDateLeadByDaysCheck();
      }
    }
  }

  setPhone(organizerPhone: string | undefined): void {
    if (organizerPhone !== undefined) {
      this.organizerPhone = organizerPhone;
    }
  }

  dealerUserEventChanged(): boolean {
    const { eventName } = this.form.controls;
    const eventDateFormValue = this.eventDate;
    const eventNameFormValue = eventName?.value;

    if (
      this.dealerUserEvent?.eventDate !== eventDateFormValue ||
      this.dealerUserEvent?.eventName !== eventNameFormValue
    ) {
      return true;
    }
    return false;
  }

  onSubmit(): void {
    if (this.dealerUserEvent && this.event) {
      this.updateEvent();
    } else {
      this.createEvent();
    }
  }

  eventDateLeadByDaysCheck(): void {
    const eventDate = convertUnixTimestampToDate(this.eventDate);
    eventDate.setHours(0, 0, 0, 0);
    const parsedDate = dayjs(eventDate);

    const newDate: Date = new Date();
    newDate.setHours(0, 0, 0, 0);
    newDate.setDate(newDate.getDate() + getOnlineLeadTimeDays());

    const dateDiff: number = parsedDate.diff(newDate);

    if (dateDiff >= 0) {
      this.eventDateWarn = false;
    } else {
      this.eventDateWarn = true;
    }
  }

  setEventFormWarning(): void {
    console.log('setEventFormWarning...');
    const { eventName, eventType, organizerPhone } = this.form.controls;
    if (this.form.invalid) {
      if (eventName.invalid && eventName.dirty) {
        if (eventName.errors?.schemaFieldErrors[0].includes('required')) {
          console.log(
            "Setting eventFormWarning to 'required' because of eventName"
          );
          this.eventFormWarning = 'required';
          return;
        } else {
          // console.log("Setting eventFormWarning to 'other' because of eventName");
          // this.eventFormWarning = 'other';
          return;
        }
      }
      if (eventType.invalid && eventType.dirty) {
        if (eventType.errors?.schemaFieldErrors[0].includes('required')) {
          console.log(
            "Setting eventFormWarning to 'required' because of eventType"
          );
          this.eventFormWarning = 'required';
          return;
        } else {
          console.log(
            "Setting eventFormWarning to 'other' because of eventType"
          );
          this.eventFormWarning = 'other';
          return;
        }
      }
      if (organizerPhone?.invalid && organizerPhone?.dirty) {
        if (organizerPhone?.errors?.schemaFieldErrors[0].includes('required')) {
          console.log(
            "Setting eventFormWarning to 'required' because of organizerPhone"
          );
          this.eventFormWarning = 'required';
          return;
        } else {
          console.log(
            "Setting eventFormWarning to 'other' because of organizerPhone"
          );
          this.eventFormWarning = 'other';
          return;
        }
      }
      console.log(
        'Event form is invalid, but none of the fields are invalid && dirty. Removing warning'
      );
      this.eventFormWarning = false;
      return;
    }
    console.log(
      'Event form is not invalid.  Setting eventFormWarning to FALSE'
    );
    this.eventFormWarning = false;
  }

  setOrganizerFormWarning(): void {
    const {
      organizerFirstName,
      organizerLastName,
      organizerPhone,
      organizerEmail,
    } = this.form.controls;
    if (this.form.invalid) {
      // console.log("organizer form is invalid");
      if (
        (organizerFirstName.invalid && organizerFirstName.dirty) ||
        (organizerLastName.invalid && organizerLastName.dirty) ||
        (organizerPhone?.invalid && organizerPhone.dirty) ||
        (organizerEmail.invalid && organizerEmail.dirty)
      ) {
        // console.log("Setting organizerFormWarning.")
        // console.log(organizerEmail.errors)
        if (organizerEmail.errors?.schemaFieldErrors[0].includes('email')) {
          this.organizerFormWarning = 'email';
        } else {
          this.organizerFormWarning = 'required';
        }
      }
    } else {
      this.organizerFormWarning = '';
    }

    if (organizerEmail.value === this.user?.email) {
      // console.log("setting organizerEmailMatchWarn")
      this.organizerEmailMatchWarn = true;
      this.form.controls.organizerEmail.markAsTouched();
    }
  }

  /** Checks to see if there are any changes to name, date, or type.  Then calls the event api, which updates the event details for the event and related dealerUserEvents */
  private async updateEventDetails() {
    if (this.event === undefined) {
      console.error('Event is undefined in updateEventDetails');
      return;
    }

    const { eventName, eventType } = this.form.value;
    const eventDate = this.eventDate;

    // build the update object
    let eventDetailsUpdates: Parameters<
      typeof this.ecomSharedModuleService.updateEventDetails
    >[1] = {};

    if (eventName !== undefined && eventName.trim() !== this.event.eventName) {
      console.log('Event name changed');
      eventDetailsUpdates.eventName = eventName.trim();
    }
    if (
      eventType !== undefined &&
      eventType !== this.event.eventType &&
      eventType !== ''
    ) {
      console.log('Event type changed');
      eventDetailsUpdates.eventType = eventType;
    }
    if (eventDate !== this.event.eventDate) {
      // eventDate is set directly by the date form component, so we don't use the actual valueChanges object
      console.log('Event date changed');
      eventDetailsUpdates.eventDate = eventDate;
    }

    if (Object.keys(eventDetailsUpdates).length === 0) {
      console.log('No changes to event details');
      return;
    }

    console.log('Saving with updateEventDetails');
    this.isSaving = true;
    return this.ecomSharedModuleService.updateEventDetails(
      this.event.id,
      eventDetailsUpdates
    );
  }

  /** Checks to see if there are any changes to organizer details, then calls the event api to update organizerInfo */
  private async updateOrganizerInfo() {
    if (this.event === undefined) {
      console.error('Event is undefined in updateOrganizerInfo');
      return;
    }

    const firstName = this.form.controls.organizerFirstName.value.trim();
    const lastName = this.form.controls.organizerLastName.value.trim();
    const email = this.form.controls.organizerEmail.value.trim();
    const name = getNameFromFirstAndLastName(firstName, lastName);

    const currentAdmin = this.event.admins[0];
    const updatedAdmin: EventAdmin = {
      id: currentAdmin.id,
      name,
      role: '',
      firstName,
      lastName,
      email,
      phone: this.organizerPhone,
      isOrganizer: true,
    };
    let needsUpdate = false;
    for (const key in updatedAdmin) {
      if (
        updatedAdmin[key as keyof EventAdmin] !==
        currentAdmin[key as keyof EventAdmin]
      ) {
        needsUpdate = true;
        break;
      }
    }

    if (!needsUpdate) {
      console.log('No changes to organizer info');
      return;
    }

    console.log('Updating organizer info');

    /// see note below for why this check is necessary.
    if (currentAdmin.id === '') {
      const organizers = this.event.admins.filter((admin) => admin.isOrganizer);
      const numOrganizers = organizers.length;

      if (numOrganizers !== 1) {
        // this shouldn't really happen because there should always only be one admin with isOrganizer set to true.
        throw new Error(
          'This organizer cannot be updated.  This admin has no id and there is not exactly one organizer in the admins array.'
        );
      }
    }

    /// There are events where the organizer has an id of empty string.
    /// The api will update an organizer with no id if we pass 'organizer' as the adminId,
    /// but only if there is exactly one admin with isOrganizer set to true and that admin's id is the empty string.
    const adminId = currentAdmin.id === '' ? 'organizer' : currentAdmin.id;

    return this.ecomSharedModuleService.updateOrganizer(
      this.event.id,
      adminId,
      updatedAdmin
    );
  }

  /** Handles saves for both the Event Details and the Organizer Details. */
  async updateEvent() {
    if (this.form.invalid || this.eventDateWarn) {
      this.setEventFormWarning();
      this.setOrganizerFormWarning();
      return;
    }

    if (this.user && this.event) {
      this.form.disable();
      this.isSaving = true;
      try {
        // Either or both of these functions can call the event api, so we need to await both of them,
        // Then we need to check the results to see if we need to update the selected event
        const updateEventAfterOrganizerSave = (await this.updateOrganizerInfo())
          ?.updatedEvent;
        const updatedEventAfterEventDetailsSave = (
          await this.updateEventDetails()
        )?.updatedEvent;
        if (
          updateEventAfterOrganizerSave &&
          !updatedEventAfterEventDetailsSave
        ) {
          console.log(
            'Setting selected event with updated event after only saving organizer details.'
          );
          this.eventService.setSelectedEventWithEvent(
            updateEventAfterOrganizerSave,
            'DealerEventDetailsComponent -- updateEvent()'
          );
        } else if (
          updatedEventAfterEventDetailsSave &&
          !updateEventAfterOrganizerSave
        ) {
          console.log(
            'Setting selected event with updated event after only saving event details.'
          );
          this.eventService.setSelectedEventWithEvent(
            updatedEventAfterEventDetailsSave,
            'DealerEventDetailsComponent -- updateEvent()'
          );
        } else if (
          updateEventAfterOrganizerSave &&
          updatedEventAfterEventDetailsSave
        ) {
          console.log(
            'Setting selected event with updated event after both saves (organizer details and event details)'
          );
          this.eventService.setSelectedEventWithEvent(
            updatedEventAfterEventDetailsSave,
            'DealerEventDetailsComponent -- updateEvent()'
          );
        } else {
          // This should never happen because both functions should return something or throw an error
          console.log(
            'No changes to event or organizer details.  No need to update selected event.'
          );
        }
      } catch (error) {
        console.error('Error updating event details', error);
        alert(
          'Sorry, there was an error saving your event details.  Please try again or contact Customer Service.'
        );
        // Recover by reloading the event
        try {
          console.log('Reloading event after error');
          this.loading = true;
          const event = await this.eventService.getSelectedEvent(true, 'DealerEventDetailsComponent -- updateEvent()');
          console.log("Event successfully reloaded.  Re-initializing event details...");
          await this.initializeEventDetails(event, this.editMode);
          console.log('Event details re-initialized.  Re-enabling form.');
          this.form.enable();
          this.isSaving = false;
          return;
        } catch (error) {
          // If we can't reload the event, reload the page
          console.error('Error reloading event', error);
          this.form.enable();
          this.isSaving = false;
          alert('The page will reload now.');
          window.location.reload();
          return;
        }
      }
    }

    const navigated = await this.eventService.routeToLastStep();
    if (!navigated) {
      console.error('There was a problem navigating to the next step.');
      this.form.enable();
      this.isSaving = false;
    }

    // const newEventDate =
    //   this.eventDate === 0 ? this.event.eventDate : this.eventDate;
    // updatedEvent.eventName = this.form.controls.eventName.value;
    // updatedEvent.eventType = this.form.controls.eventType.value;
    // updatedEvent.eventDate = newEventDate;
    // updatedEvent.admins = [admin];
    // There should only be one admin here.

    // if (this.dealerUserEvent && this.dealerUserEventChanged()) {
    //   const newDealerUserEvent: DealerUserEvent = {
    //     ...this.dealerUserEvent,
    //     eventName: this.form.controls.eventName?.value,
    //     eventDate: newEventDate,
    //   };

    //   await this.dealerEventService.updateDealerUserEventById(
    //     this.dealerUserEvent.eventId,
    //     newDealerUserEvent
    //   );

    // Moving this outside of the if-logic
    //this.eventService.routeToLastStep();
    // }

    // this.eventService.routeToLastStep();
    // this.eventUpdating = false;
    // } catch (error) {
    //   this.eventUpdating = false;
    //   alert('There was an unexpected error');
    // }
    // }
  }

  async createEvent(): Promise<void> {
    if (
      this.form.invalid ||
      this.eventDateWarn ||
      this.form.controls.eventType.value === ''
    ) {
      this.setEventFormWarning();
      this.setOrganizerFormWarning();
      return;
    }

    const firstName = this.form.controls.organizerFirstName.value;
    const lastName = this.form.controls.organizerLastName.value;

    const organizer: EventAdminNoId = {
      // id is generated in api
      firstName,
      lastName,
      name: `${firstName} ${lastName}`,
      phone: this.organizerPhone,
      email: this.form.controls.organizerEmail.value,
      isOrganizer: true,
      role: '',
    };

    // const admin: EventAdmin = {
    //   id: organizer.id!,
    //   name: `${organizer.firstName} ${organizer.lastName}`,
    //   firstName: organizer.firstName,
    //   lastName: organizer.lastName,
    //   role: organizer.memberRole,
    //   email: organizer.email!,
    //   phone: organizer.phone!,
    //   isOrganizer: true,
    // };

    if (this.user) {
      let dealerId = this.dealerIds.length > 0 ? this.dealerIds[0] : '';

      let createdByDealer = {
        userId: this.user.uid,
        name: this.user.displayName ? this.user.displayName : '',
        email: this.user.email ? this.user.email : '',
      };

      const dealerInfo: DealerInfo = {
        dealerId: dealerId,
        createdByDealer: createdByDealer,
      };

      const event: Event = {
        id: '',
        dealerId: dealerId,
        dealerInfo: dealerInfo,
        eventName: this.form.controls.eventName.value,
        eventType: this.form.controls.eventType.value,
        eventDate: this.eventDate,
        looks: [],
        memberIds: [],
        members: [],
        admins: [], // api adds the organizer as admin
        // admins: [admin],
      };
      this.isSaving = true;
      try {
        const newEvent = await this.dealerEventService.createEvent(
          event,
          organizer
        );
        console.log('Event created', newEvent);
        return;
      } catch (error) {
        console.error('Error creating event', error);
        alert('There was an error creating your event');
        this.isSaving = false;
        return;
      }
    }
  }
}
