import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EventService } from '@jfw-library/ecommerce/core';
import { DealerAccountElastic, Event } from 'common-types';
import { StoreService } from '../../../services/store/store.service';

@Component({
  selector: 'app-store-info-modal',
  templateUrl: './store-info-modal.component.html',
  styleUrls: ['./store-info-modal.component.scss'],
})
export class StoreInfoModalComponent implements OnInit {
  @Output() storeSelected = new EventEmitter<DealerAccountElastic>();

  private event: Event = {} as Event;
  public savingStore: boolean = false;
  private document: Document = inject(DOCUMENT);

  constructor(
    public storeService: StoreService,
    public dialogRef: MatDialogRef<StoreInfoModalComponent>,
    private eventService: EventService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public store: DealerAccountElastic,
  ) { }

  ngOnInit(): void {
    this.eventService.getSelectedEvent(false, 'StoreInfoModalComponent - ngOnInit()').then((event) => {
      this.event = event;
    });
  }

  public canSelectStore(): boolean {
    // Don't allow selection if not on choose-store
    const currentRoute = this.router.url;
    if (currentRoute.includes('choose-store')) {
      return true;
    }
    return false;
  }

  public onGetDirectionsClick(): void {
    // let coordinates = `${this.store?.lat},${this.store?.lon}`;
    let coordinates = `${this.store?.location.lat},${this.store?.location.lon}`;
    let url =
      'https://www.google.com/maps/dir/?api=1&destination=' + coordinates;
    window.open(url, '_blank');
  }

  public openDialer(phone: string | undefined): void {
    if (phone) {
      this.document.location.href = `tel:${phone}`;
    }
  }

  public async selectStore(
    store: DealerAccountElastic | undefined,
  ): Promise<void> {
    if (store !== null && store !== undefined) {
      this.savingStore = true;

      try {
        await this.storeService.setStoreForEvent(this.event, store);
        this.storeSelected.emit(store);
        this.savingStore = false;
      } catch (error) {
        alert('Something went wrong selecting store.');
        this.savingStore = false;
      }
    }
  }

  public openWebsite(website: string | undefined): void {
    let url = 'https://' + website;
    if (website) {
      window.open(url, '_blank');
    }
  }
}
