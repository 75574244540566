import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthV1Service, AuthV2Service } from '@jfw-library/ecommerce/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable({
  providedIn: 'root',
})
export class AuthRequiredGuard {
  constructor(
    @Inject('environment') private environment: any,
    private router: Router,
    private authV1Service: AuthV1Service,
    private authV2Service: AuthV2Service,
    private cookieService: SsrCookieService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve: any, reject: any) => {
      console.log(
        '%c* AUTH REQUIRED GUARD START *',
        'background-color: orange;color:black'
      );

      if (this.authV2Service.isAuthorizedAnonymous) {
        // console.log('ANON Guard -> isAuthorizedAnonymous: true');
        resolve(true);
      } else {
        // console.log(
        //   'ANON Guard -> is NOT Anonymous ... checking Authorized Status'
        // );
        if (this.authV2Service.isLoggedIn) {
          this.authV1Service.getUserHasEcomAccess(true).then((allowed) => {
            console.log('ANON Guard Allowed:');
            console.log(allowed);

            if (allowed) {
              resolve(true);
            } else {
              if (!this.environment.prod_mode_authorization_rules) {
                this.router.navigate(['V2login']).then(() => {
                  //window.location.reload();
                });
              }
              resolve(false);
            }
          });
        } else {
          console.log('ANON Guard -> NOT AUTHORIZED! -> Routing to V2login');

          if (!this.environment.prod_mode_authorization_rules) {
            this.router.navigate(['V2login']).then(() => {
              //window.location.reload();
            });
          }
          resolve(false);
        }
      }
    });
  }
}
