import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { SharedCommunicationQueueService } from '@jfw-library/shared/services';
import {
  convertUnixTimestampToPrettyDate,
  getOrganizerFromEvent,
} from 'business-logic';
import {
  CommunicationQueue,
  EmailUsForm,
  EmailUsFormResponse,
  Event,
  MessageType,
  Site,
} from 'common-types';
import { Observable, catchError, firstValueFrom, of } from 'rxjs';
import { StoreService } from '../store/store.service';

@Injectable({
  providedIn: 'root',
})
export class EcomCommunicationQueueService {
  constructor(
    @Inject('environment')
    private environment: any,
    private sharedCommQService: SharedCommunicationQueueService,
    private httpClient: HttpClient,
    private storeService: StoreService
  ) {}

  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  ///////////////////////////////////////////////////////////////////////////
  //  SHARED METHODS                                                       //
  ///////////////////////////////////////////////////////////////////////////

  private sendToCommunicationQueue(
    queueData: Partial<CommunicationQueue>
  ): Promise<Partial<CommunicationQueue>> {
    return this.sharedCommQService.sendToCommunicationQueue(queueData);
  }

  public async queueEmailMemberInvite(
    event: Event,
    eventMemberEmail: string,
    memberFirstName: string | undefined,
    memberLastName: string | undefined,
    isOrganizer: boolean = false
  ): Promise<Partial<CommunicationQueue>> {
    return this.sharedCommQService.queueEmailMemberInvite(
      event,
      eventMemberEmail,
      memberFirstName,
      memberLastName,
      isOrganizer
    );
  }

  public async queueEmailCoOwnerInvite(
    event: Event,
    eventMemberEmail: string,
    coOwnerFirstName: string | undefined,
    coOwnerLastName: string | undefined,
    coOwnerPhone: string | undefined
  ): Promise<Partial<CommunicationQueue>> {
    return this.sharedCommQService.queueEmailCoOwnerInvite(
      event,
      eventMemberEmail,
      coOwnerFirstName,
      coOwnerLastName,
      coOwnerPhone
    );
  }

  public queueEmailDealerInvite(
    origin: Site,
    firstName: string,
    lastName: string,
    email: string,
    documentId?: string,
    displayName?: string,
    userId?: string
  ) {
    return this.sharedCommQService.queueEmailDealerInvite(
      origin,
      firstName,
      lastName,
      email,
      documentId,
      displayName,
      userId
    );
  }

  ///////////////////////////////////////////////////////////////////////////
  //  ECOM-ONLY METHODS                                                    //
  ///////////////////////////////////////////////////////////////////////////

  /**
   * Queues an 'event-transfer' email to be sent to the eventMemberEmail.
   * If a store cannot be found for the dealerId in this event, this method will return undefined.
   * Otherwise, it will return the response from the CommunicationQueue API,
   * which should be the CommunicationQueue object that was sent to the API.
   *
   */
  public async queueTransferEventEmail(
    event: Event,
    eventMemberEmail: string,
    organizerName: string
  ): Promise<Partial<CommunicationQueue> | undefined> {
    var referralPartnerName = '';
    var referralPartnerEmail = '';
    var referralPartnerPhone = '';

    let inviteUrl: string = `${this.environment.ecom_prefix_for_email_and_sms_communication}/invite/${event.id}/${eventMemberEmail}`;

    let stores = await firstValueFrom(
      this.storeService.getStoresByActNum(event.dealerId!)
    );

    if (stores && stores.length > 0) {
      const store = stores[0];
      // referralPartnerName = store.aname;
      referralPartnerName = store.aname;
      referralPartnerEmail = store.email!;
      referralPartnerPhone = store.phon;

      let queueData: Partial<CommunicationQueue> = {
        recipient: eventMemberEmail,
        communicationMethod: 'email',
        messageType: 'event-transfer',
        origin: 'dealer-portal',
        templateData: {
          event: {
            eventName: event.eventName,
            eventDate: convertUnixTimestampToPrettyDate(event.eventDate),
          },
          organizerName: organizerName,
          referralDealer: {
            name: referralPartnerName,
            email: referralPartnerEmail,
            phone: referralPartnerPhone,
          },
          inviteUrl: inviteUrl,
        },
      };

      return this.sendToCommunicationQueue(queueData);
    } else {
      console.error('No stores found for dealerId: ', event.dealerId);
      return Promise.resolve(undefined);
    }
  }

  /**
   * Sends a CommunicationQueue object to the contactUs API, which then adds the doc to the ComQ.
   * This does not use the sendToCommunicationQueue method because the contactUs API has different middleware.
   * @param data The form data from the contact us form.
   */
  public queueEmailContactUs(
    data: EmailUsForm
  ): Observable<EmailUsFormResponse> {
    const {
      firstName,
      lastName,
      email,
      phone,
      eventDate,
      comments,
      recaptcha,
    } = data;
    let queueData: Partial<CommunicationQueue> = {
      // recipient: 'website-support@jimsfw.com', // these properties are all now set in the api.  Can't trust the client.
      // communicationMethod: 'email',
      // messageType: 'contact-us',
      origin: Site.Ecom,
      templateData: data,
    };

    const url = `${this.environment.contact_us_rest_api_server_url}`;
    return this.httpClient
      .post<EmailUsFormResponse>(url, queueData, {
        ...requireAppCheck,
        headers: this.reqHeader,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          console.error(err.error);
          return of(err.error);
        })
      );
  }

  public queueInStoreShare(event: Event) {
    let organizer = getOrganizerFromEvent(event);
    let store = event.inStoreInfo?.store;
    if (organizer && store) {
      let templateData = {
        organizer: {
          firstName: organizer.firstName ?? '',
          lastName: organizer.lastName ?? '',
          email: organizer.email,
          phone: event.inStoreInfo?.shareEventDetails?.phone ?? '',
        },
        event: {
          description: event.eventName,
          date: convertUnixTimestampToPrettyDate(event.eventDate),
          type: event.eventType,
          link: `${this.environment.ecom_prefix_for_email_and_sms_communication}/event/${event.id}/confirmation`,
          size: event.inStoreInfo?.shareEventDetails?.eventSize ?? 0,
        },
        store: {
          name: store.aname,
          actnum: store.actnum,
          email:
            this.environment.envVersion === 'prod'
              ? store.email
              : organizer.email,
          phone: store.phone,
          address: {
            adr1: store.address1,
            adr2: store.address2,
            city: store.city,
            state: store.state,
            zip: store.zipCode,
          },
        },
      };
      console.log('templateData: ', templateData);
      let communicationsToSend: Array<Partial<CommunicationQueue>> = [];
      let inStoreCustomerEmail: Partial<CommunicationQueue> = {
        recipient: templateData.organizer.email,
        communicationMethod: 'email',
        messageType: MessageType.in_store_share_consumer,
        origin: 'ecom',
        templateData: templateData,
      };
      communicationsToSend.push(inStoreCustomerEmail);
      let inStoreDealerEmail: Partial<CommunicationQueue> = {
        recipient: templateData.store.email,
        communicationMethod: 'email',
        messageType: MessageType.in_store_share_dealer,
        origin: 'ecom',
        templateData: templateData,
      };
      communicationsToSend.push(inStoreDealerEmail);
      if (templateData.organizer.phone !== '') {
        let inStoreCustomerText: Partial<CommunicationQueue> = {
          recipient: templateData.organizer.phone,
          communicationMethod: 'sms',
          messageType: MessageType.in_store_share_consumer,
          origin: 'ecom',
          templateData: templateData,
        };
        communicationsToSend.push(inStoreCustomerText);
      }
      return Promise.allSettled(
        communicationsToSend.map(
        (itemToSend: Partial<CommunicationQueue>) => {
            return this.sendToCommunicationQueue(itemToSend);
        }
        ));
    } else {
      console.error('Organizer or store not found for event: ', event);
      return Promise.resolve(undefined);
    }
  }

  /*
  public queueEmailPasswordResetSuccess(
    firstName: string,
    lastName: string,
    email: string
  ): void {
    let queueData: Partial<CommunicationQueue> = {
      recipient: email,
      communicationMethod: 'email',
      messageType: 'password-reset-success',
      origin: 'ecom',
      templateData: {
        user: {
          firstName: firstName,
          lastName: lastName,
        },
      },
    };

    this.sendToCommunicationQueue(queueData);
  }
  */
}
