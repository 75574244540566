<div class="main-container" [ngClass]="vertical ? 'vertical' : 'horizontal'">
  <div *ngIf="useButtons && !(firstItemVisible && lastItemVisible)">
    <app-carousel-button
      [direction]="vertical ? 'up' : 'left'"
      [disabled]="firstItemVisible"
      [ariaLabel]="'Previous carousel item'"
      (clickEvent)="scrollPrev()"
    ></app-carousel-button>
  </div>

  <div class="carousel-outer" [ngClass]="vertical ? 'vertical' : 'horizontal'">
    <div
      #carousel
      class="carousel-inner"
      [ngClass]="vertical ? 'vertical' : 'horizontal'"
      [style.scroll-padding]="vertical ? itemMargin + ' 0' : '0 ' + itemMargin"
      role="region"
      [attr.aria-label]="ariaLabel"
    >
      <div
        appCarouselItemElement
        *ngFor="let item of items; let i = index"
        [attr.data-carousel-index]="i"
        (click)="scrollToIndex(i)"
        class="carousel-item"
        [style.height]="itemHeight"
        [style.width]="itemWidth"
        [style.margin]="vertical ? itemMargin + ' 0' : '0 ' + itemMargin"
      >
        <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="useButtons && !(firstItemVisible && lastItemVisible)">
    <app-carousel-button
      [direction]="vertical ? 'down' : 'right'"
      [disabled]="lastItemVisible"
      [ariaLabel]="'Next carousel item'"
      (clickEvent)="scrollNext()"
    ></app-carousel-button>
  </div>
</div>
