import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { User } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthV1Service, EventService } from '@jfw-library/ecommerce/core';
import {
  getInStoreView,
  getMemberView,
  isUserOrganizerOrAdminForEvent,
} from 'business-logic';
import { Event, EventStep } from 'common-types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-event-header',
  templateUrl: './event-header.component.html',
  styleUrls: ['./event-header.component.scss'],
})
export class EventHeaderComponent implements OnInit, OnDestroy, OnChanges {
  event: Event | undefined;
  @Input() eventStep!: EventStep | undefined;

  // user: firebase.User | null = null;
  user: User | null = null;
  memberView = false;
  inStoreView = false;
  subscription = new Subscription();
  isAdminOrOrganizer = false;
  trackProgressEnabled = false;
  isTrackProgressPage = false;
  dealerPortal = this.environment.dealerPortal;

  constructor(
    @Inject('environment') private environment: any,
    private eventService: EventService,
    private authV1Service: AuthV1Service,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.eventService.selectedEvent$.subscribe({
        next: (event) => {
          this.event = event;
          this.setViews(event);
          this.setIsAdminOrOrganizer(event);
          this.setTrackProgressOption(event);
        },
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setIsTrackProgressPage();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setIsTrackProgressPage() {
    console.log("setIsTrackProgressPage()")
    const segments = this.router.url.split('/');
    if (segments[segments.length - 1].toLowerCase() === 'track-progress') {
      this.isTrackProgressPage = true;
    } else {
      this.isTrackProgressPage = false;
    }
  }

  setViews(event: Event): void {
    this.memberView = getMemberView(event);
    this.inStoreView = getInStoreView(event);
  }

  setIsAdminOrOrganizer(event: Event): void {
    if (!this.dealerPortal) {
      if (this.user === null) {
        this.user = this.authV1Service.getCurrentUser();
      }
    }

    this.isAdminOrOrganizer = isUserOrganizerOrAdminForEvent(event);
  }

  setTrackProgressOption(event: Event): void {
    if (this.dealerPortal) {
      this.trackProgressEnabled = false;
      this.memberView = false;
    } else {
      event?.members.forEach((member) => {
        if (member.memberProgress?.paid !== undefined) {
          this.trackProgressEnabled = true;
        }
      });
    }
  }
}
