<div class="main">
  <div [ngClass]="!isLoading() ? 'main-container' : 'hidden'">
    <div [ngClass]="isMobile() ? 'hidden' : 'store-locator-desktop'">
      <h1 *ngIf="!event">FIND A STORE</h1>
      <div class="map-banner-container">
        <div class="map-search-bar">
          <form (ngSubmit)="getLocationByZipcode()">
            <mat-form-field appearance="outline" class="search-field">
              <mat-label>Search</mat-label>
              <input
                name="zipcode"
                #mapSearchField
                matInput
                [(ngModel)]="zipcode"
                type="text"
                placeholder="Search by City, State or Zip"
              />
              <button matSuffix mat-icon-button>
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </form>
        </div>

        <div class="or-text">
          <strong>or</strong>
        </div>

        <div class="my-location-button-container">
          <button mat-flat-button color="primary" (click)="getUserLocation()">
            Use My Current Location
          </button>
        </div>
      </div>
    </div>

    <div
      [ngClass]="
        isMobile() ? 'store-locator-mobile' : 'store-locator-mobile hidden'
      "
      >
      <h1 *ngIf="!event">FIND A STORE</h1>
      <form (ngSubmit)="getLocationByZipcode()">
        <mat-form-field class="search-field">
          <mat-label>Search</mat-label>
          <input
            #mapSearchFieldMobile
            name="zipcode"
            matInput
            [(ngModel)]="zipcode"
            type="text"
            matInput
            placeholder="Search by City, State or Zip"
          />
          <button matSuffix mat-icon-button>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </form>

      <div class="use-location-nav-container">
        OR<br /><br />
        <button mat-flat-button color="primary" (click)="getUserLocation()">
          Use My Current Location
        </button>
      </div>
    </div>

    <div class="store-map-container" *ngIf="showMap()" id="gMap">
      <app-store-map
        [searchCoordinates]="searchCoordinates()"
        [event]="event"
        [center]="center()"
        [chooseStore]="chooseStore"
        [isSaving]="isSaving()"
        (storeList)="populateStoreList($event)"
        (selectStore)="selectStore($event)"
        (storeNameClicked)="scrollToStore($event)"
      ></app-store-map>
    </div>

    <div #storeListContainer class="store-list-container">
      <div class="stores">
        <ng-container *ngFor="let store of storesToShow(); index as i">
          <div class="store-wrapper">
            <div
              id="store-{{ store.aname }}"
              [ngClass]="
                store.aname === storeToHighlight() ? 'store-clicked'
                : store.aname === selectedStoreName() ? 'store-selected'
                : 'store'
              "
            >
              <p
                id="selectedStore"
                *ngIf="
                  store.actnum ===
                  event?.inStoreInfo?.store?.actnum
                "
              ></p>
              <p>
                <a
                  class="store-name"
                  (click)="storeDetails(store)"
                  disableRipple
                  tabindex="0"
                  >{{ store.aname }}</a
                >
              </p>
              <p>
                {{ store.address1 }}
                {{ store.address2 }}
              </p>
              <p>
                {{ store.city }},
                {{ store.state | uppercase }}
                {{ store.zipCode }}
              </p>
              <p>{{ store.phone }}</p>
              <p class="distance">
                {{ store.distance | number : "1.1-2" }} miles
              </p>

              <p class="store-info-nav-container">
                <a
                  class="store-info-nav"
                  (click)="storeDetails(store)"
                  tabindex="0"
                  >Store Info</a
                >
              </p>
              <div class="select-store-button-container">
                <button
                  tabindex="0"
                  *ngIf="event !== undefined && !saveSelectingStoreIndex()[i]"
                  mat-flat-button
                  color="primary"
                  data-cy="select-store"
                  [disabled]="isSaving() || selectedStoreName() === store?.aname"
                  (click)="selectStore(store, i)"
                >
                  {{selectedStoreName() === store?.aname ? 'Store Selected' : 'Select this Store'}}
                </button>
                <mat-spinner
                  *ngIf="saveSelectingStoreIndex()[i]"
                  diameter="30"
                ></mat-spinner>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="back-to-top-container">
      <button
        *ngIf="showScrollToTop()"
        [ngClass]="
          isMobile() ? 'back-to-top-button' : 'back-to-top-button hidden'
        "
        mat-stroked-button
        (click)="scrollToTop()"
      >
        <mat-icon class="back-to-top-icon">keyboard_double_arrow_up</mat-icon>
        <span>BACK TO TOP</span>
      </button>
    </div>
  </div>
</div>
<div *ngIf="isLoading()" class="spinner-container">
  <mat-spinner></mat-spinner>
</div>
