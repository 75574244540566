import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(phone: string | undefined) {
    if (phone !== undefined) {
      const areaCode: string = phone.substring(0, 3);
      const prefix: string = phone.substring(3, 6);
      const lineNumber: string = phone.substring(6);
      return `(${areaCode}) ${prefix}-${lineNumber}`;
    } else {
      return phone;
    }
  }
}
