import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, NgZone, Optional, PLATFORM_ID } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { ReCaptchaEnterprise } from "./recaptchaV3.types";

import { loader } from "./load-script";
import { RECAPTCHA_BASE_URL, RECAPTCHA_LANGUAGE, RECAPTCHA_NONCE, RECAPTCHA_V3_SITE_KEY } from "./tokens";
import { ActionBacklogEntry, OnExecuteData, OnExecuteErrorData } from "./recaptcha-services.types";

export {OnExecuteData, OnExecuteErrorData}

declare var grecaptcha: ReCaptchaEnterprise;


/**
 * The main service for working with reCAPTCHA v3 (and enterprise) APIs.
 *
 * Use the `execute` method for executing a single action, and
 * `onExecute` observable for listening to all actions at once.
 */
@Injectable()
export class ReCaptchaScoreService {
  /** @internal */
  private readonly isBrowser: boolean;
  /** @internal */
  private readonly siteKey: string;
  /** @internal */
  private readonly zone: NgZone;
  /** @internal */
  private actionBacklog: ActionBacklogEntry[] | undefined;
  /** @internal */
  private nonce: string | undefined;
  /** @internal */
  private language?: string;
  /** @internal */
  private baseUrl: string | undefined;
  /** @internal */
  private grecaptcha: ReCaptchaEnterprise |  undefined;

  /** @internal */
  private onExecuteSubject: Subject<OnExecuteData> | undefined;
  /** @internal */
  private onExecuteErrorSubject: Subject<OnExecuteErrorData> | undefined;
  /** @internal */
  private onExecuteObservable: Observable<OnExecuteData> | undefined;
  /** @internal */
  private onExecuteErrorObservable: Observable<OnExecuteErrorData> | undefined;

  constructor(
    zone: NgZone,
    @Inject(RECAPTCHA_V3_SITE_KEY) siteKey: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) platformId: Object,
    @Optional() @Inject(RECAPTCHA_BASE_URL) baseUrl?: string,
    @Optional() @Inject(RECAPTCHA_NONCE) nonce?: string,
    @Optional() @Inject(RECAPTCHA_LANGUAGE) language?: string,
  ) {
    this.zone = zone;
    this.isBrowser = isPlatformBrowser(platformId);
    this.siteKey = siteKey;
    this.nonce = nonce;
    this.language = language;
    this.baseUrl = baseUrl;

    this.init();
  }

  public get onExecute$(): Observable<OnExecuteData> {
    if (!this.onExecuteSubject || !this.onExecuteObservable) {
      this.onExecuteSubject = new Subject<OnExecuteData>();
      this.onExecuteObservable = this.onExecuteSubject.asObservable();
    }

    return this.onExecuteObservable;
  }

  public get onExecuteError$(): Observable<OnExecuteErrorData> {
    if (!this.onExecuteErrorSubject || !this.onExecuteErrorObservable) {
      this.onExecuteErrorSubject = new Subject<OnExecuteErrorData>();
      this.onExecuteErrorObservable = this.onExecuteErrorSubject.asObservable();
    }

    return this.onExecuteErrorObservable;
  }

  /**
   * Executes the provided `action` with reCAPTCHA v3 API.
   * Use the emitted token value for verification purposes on the backend.
   *
   * For more information about reCAPTCHA v3 actions and tokens refer to the official documentation at
   * https://developers.google.com/recaptcha/docs/v3.
   *
   * @param {string} action the action to execute
   * @returns {Observable<string>} an `Observable` that will emit the reCAPTCHA v3 string `token` value whenever ready.
   * The returned `Observable` completes immediately after emitting a value.
   */
  public execute(action: string): Observable<string> {
    const subject = new Subject<string>();
    if (this.isBrowser) {
      if (!this.grecaptcha) {
        if (!this.actionBacklog) {
          this.actionBacklog = [];
        }

        this.actionBacklog.push([action, subject]);
      } else {
        this.executeActionWithSubject(action, subject);
      }
    }

    return subject.asObservable();
  }

  // WIP: not sure if we need this since we're using v3
  // public renderCheckbox(element: HTMLElement, options: ReCaptchaV2.Parameters): number {
  //   if(!this.grecaptcha) {
  //     console.error("reCAPTCHA v3 not loaded yet");
  //     return -1;
  //   } else {
  //     return this.grecaptcha.render(element, options);
  //   }
  // }

  /** @internal */
  private executeActionWithSubject(action: string, subject: Subject<string>): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onError = (error: any) => {
      this.zone.run(() => {
        subject.error(error);
        if (this.onExecuteErrorSubject) {
          // We don't know any better at this point, unfortunately, so have to resort to `any`
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          this.onExecuteErrorSubject.next({ action, error });
        }
      });
    };

    this.zone.runOutsideAngular(() => {
      if(this.grecaptcha) {
        console.log("running grecaptcha with siteKey: ", this.siteKey)
        try {
          this.grecaptcha.enterprise.execute(this.siteKey, { action }).then((token: string) => {
            this.zone.run(() => {
              subject.next(token);
              subject.complete();
              if (this.onExecuteSubject) {
                this.onExecuteSubject.next({ action, token });
              }
            });
          }, onError);
        } catch (e) {
          onError(e);
        }
      } else {
        console.error("reCAPTCHA v3 not loaded yet");
      }
    });
  }

  /** @internal */
  private init() {
    if (this.isBrowser) {
      if ("grecaptcha" in window) {
        this.grecaptcha = grecaptcha;
      } else {
        const langParam = this.language ? "&hl=" + this.language : "";
        loader.loadV3Script(this.siteKey, this.onLoadComplete, langParam, this.baseUrl, this.nonce);
      }
    }
  }

  /** @internal */
  private onLoadComplete = (grecaptcha: ReCaptchaEnterprise) => {
    this.grecaptcha = grecaptcha;
    if (this.actionBacklog && this.actionBacklog.length > 0) {
      this.actionBacklog.forEach(([action, subject]) => this.executeActionWithSubject(action, subject));
      this.actionBacklog = undefined;
    }
  };
}
