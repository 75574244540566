import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { Measurements, Order } from 'common-types';
import { Observable } from 'rxjs/internal/Observable';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private ORDER_REST_API_SERVER = this.environment.order_rest_api_server_url;

  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient
  ) {}

  public updateOrderMeasurements(
    eventId: string,
    memberId: string,
    measurements: Measurements
  ): Promise<Order> {
    return firstValueFrom(
      this.httpClient.put<Order>(
        `${this.ORDER_REST_API_SERVER}/event/${eventId}/memberId/${memberId}`,
        measurements,
        {
          ...requireAppCheck,
          headers: this.reqHeader,
        }
      )
    );
  }

  public getInvalidOrders(): Observable<Array<Order>> {
    return this.httpClient.get<Array<Order>>(
      this.ORDER_REST_API_SERVER + '/invalid/',
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  public getOrder(documentId: string): Observable<Order> {
    return this.httpClient.get<Order>(
      this.ORDER_REST_API_SERVER + '/' + documentId,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  public placeOrder(order: Order) {
    return this.httpClient.put(this.ORDER_REST_API_SERVER + '/', order, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }
}
