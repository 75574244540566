import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CartService, EventService } from '@jfw-library/ecommerce/core';
import {
  IndividualOrGroup,
  IndividualOrGroupType,
  OnlineOrInStore,
  OnlineOrInStoreType,
} from '@jfw-library/ecommerce/zod-forms';
import {
  canEventBeCreatedOnline,
  getMaxDateForEvent,
  getOnlineLeadTimeDays,
} from 'business-logic';
import {
  Event,
  EventSchema,
  EventType,
  EventTypeOptions,
  InStoreInfo,
} from 'common-types';
import { EcommerceSharedModuleEventService } from '../../services/event/shared/ecommerce-shared-module-event.service';
import { NewEventModalComponentData } from '../../types';
interface NewEventModalForm {
  //lookName: FormControl<string>;
  eventName: FormControl<string>;
  eventType: FormControl<EventType | ''>;
  individualOrGroup: FormControl<IndividualOrGroupType>;
  // eventDate?: ObservedValueOf<DateFormComponent['formReady']>;
  eventDate: FormControl<number>;
  onlineOrInStore: FormControl<OnlineOrInStoreType>;
}

const dateIsTooSoonValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const form = control as FormGroup<NewEventModalForm>;
  if (!form || !form.controls.eventDate || !form.controls.onlineOrInStore) {
    return null;
  }
  const eventDate = form.controls.eventDate.value;
  const isOnline =
    form.controls.onlineOrInStore.value === OnlineOrInStore.online;
  if (eventDate !== 0 && isOnline) {
    if (!canEventBeCreatedOnline(eventDate)) {
      // console.log("Date is too soon in dateIsTooSoonValidator");
      return { dateIsTooSoon: true };
    }
    // console.log("Date is not too soon in dateIsTooSoonValidator");
    return null;
  }
  // console.log("event is not online in dateIsTooSoonValidator");
  return null;
};

@Component({
  selector: 'app-new-event-modal',
  templateUrl: './new-event-modal.component.html',
  styleUrls: ['./new-event-modal.component.scss'],
})
export class NewEventModalComponent implements OnInit {
  minDateInStore = new Date();
  minDateOnline = new Date();
  maxDate = getMaxDateForEvent();
  // onlineLeadTimeDays = getOnlineLeadTimeDays();
  // dateWarning = false;
  creatingEvent = false;
  // eventDate = 0;
  readonly IndividualOrGroup = IndividualOrGroup;
  readonly OnlineOrInStore = OnlineOrInStore;
  readonly EventTypeOptions = EventTypeOptions;

  form: FormGroup<NewEventModalForm> = new FormGroup(
    {
      // lookName: new FormControl('', {
      //   nonNullable: true,
      //   validators: [Validators.required],
      // }),
      eventName: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      eventType: new FormControl<EventType | ''>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      eventDate: new FormControl<number>(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      individualOrGroup: new FormControl<IndividualOrGroupType>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      onlineOrInStore: new FormControl<OnlineOrInStoreType>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    },
    { validators: [dateIsTooSoonValidator] }
  );

  private ecomSharedModuleEventService = inject(
    EcommerceSharedModuleEventService
  );

  constructor(
    @Inject('environment') private environment: any,
    @Inject(MAT_DIALOG_DATA)
    public data: NewEventModalComponentData,
    public dialogRef: MatDialogRef<NewEventModalComponent>,
    public dialog: MatDialog,
    public eventService: EventService,
    private cdr: ChangeDetectorRef,
    private cartService: CartService
  ) { }

  ngOnInit(): void {
    // const lookName = this.data.newEvent.looks[0].title;
    //this.form.controls.lookName.setValue(lookName);
    // this.onlineLeadTimeDays = getOnlineLeadTimeDays();
    this.minDateInStore.setHours(0, 0, 0, 0);
    this.minDateOnline.setHours(0, 0, 0, 0);
    this.minDateOnline.setDate(
      this.minDateOnline.getDate() + getOnlineLeadTimeDays()
    );
  }

  // updateEvent(): void {
  //   const eventName = this.form.controls.eventName.value;
  //   if (this.form.controls.eventType === undefined
  //       || this.form.controls.eventType.value === undefined
  //       || this.form.controls.eventType.value === '') {
  //     throw new Error('Event type is required');
  //   }
  //   const eventType = this.form.controls.eventType.value;

  //   const isSingleUser = this.form.controls.individualOrGroup.value === this.IndividualOrGroup.individual;

  //   const eventToSave: Event = {
  //     ...this.data.newEvent,
  //     eventName,
  //     eventType,
  //     eventDate: this.eventDate,
  //     isSingleUser,
  //   };

  //   this.data.newEvent.members = [];
  //   // if (this.form.controls.individualOrGroup.value === 'individual') {
  //   //   this.isIndividual = true;
  //   //   let memberId = generateUuid();
  //   //   const individualMember: EventMember = {
  //   //     firstName: 'Individual',
  //   //     lastName: 'User',
  //   //     memberRole: 'Event Goer',
  //   //     id: memberId,
  //   //   };
  //   //   this.data.newEvent.memberIds = [memberId];
  //   //   this.data.newEvent.members.push(individualMember);
  //   // } else {
  //   //   this.isIndividual = false;
  //   // }
  //   // this.data.newEvent.isSingleUser = this.isIndividual;

  //   if (this.form.controls.onlineOrInStore.value === 'online') {
  //     this.isOnline = true;
  //     this.data.newEvent.inStoreInfo = undefined;
  //   } else {
  //     this.isOnline = false;
  //     const info: InStoreInfo = {
  //       isCreatedByDealer: false,
  //     };
  //     this.data.newEvent.inStoreInfo = info;
  //   }

  //   // const formControls = this.form.controls;
  //   // this.data.newEvent.eventName = formControls.eventName.value;
  //   // if (formControls.eventType !== undefined && formControls.eventType.value !== undefined && formControls.eventType.value !== '') {
  //   //   this.data.newEvent.eventType = formControls.eventType.value;
  //   // }
  //   // this.data.newEvent.eventDate = this.eventDate;
  // }

  setDate(eventDate: number | undefined) {
    console.log('setDate', eventDate);
    if (eventDate !== undefined) {
      this.form.controls.eventDate.setValue(eventDate);
      this.form.updateValueAndValidity();
      // this.eventDate = eventDate;
      console.log('eventDate: ', this.form.controls.eventDate.value);
      // this.dateChange();
    }
  }

  // addChildForm<key extends keyof NewEventModalForm>(
  //   name: key,
  //   control: Exclude<NewEventModalForm[key], undefined>
  // ) {
  //   this.form.setControl(name, control);
  //   console.log("Adding form control", name, control, " and setting validators");
  //   this.form.controls.eventDate?.setValidators(this.dateIsTooSoonValidator);
  //   console.log("hasValidator", this.form.controls.eventDate?.hasValidator(this.dateIsTooSoonValidator));
  // }

  // dateChange(): void {
  //   if (this.form.controls.onlineOrInStore.value === this.OnlineOrInStore.online) {
  //     this.dateWarning = !canEventBeCreatedOnline(this.eventDate);
  //   } else {
  //     this.dateWarning = false;
  //   }
  // }

  // inStoreOnlineChange(): void {
  //   const newDate: Date = new Date();
  //   newDate.setHours(0, 0, 0, 0);
  //   if (this.form.controls.onlineOrInStore.value === this.OnlineOrInStore.online) {
  //     newDate.setDate(newDate.getDate() + this.onlineLeadTimeDays);
  //   }
  //   this.minDate = newDate;
  //   this.dateChange();
  // }

  // dateIsTooSoonValidator(): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     if (this.eventDate !== 0 && this.form.controls.onlineOrInStore.value === this.OnlineOrInStore.online) {
  //       if (!canEventBeCreatedOnline(this.eventDate)) {
  //         console.log("Date is too soon in dateIsTooSoonValidator")
  //         return { dateIsTooSoon: true };
  //       }
  //       console.log("Date is not too soon in dateIsTooSoonValidator")
  //       return null;
  //     }
  //     console.log("event is not online in dateIsTooSoonValidator");
  //     return null;
  //   };
  // }

  onBack(): void {
    this.dialogRef.close();
    // this.dialog.open<EventModalComponent, EventModalComponentData>(
    //   EventModalComponent,
    //   {
    //     // data: { newLook: this.data.newEvent.looks[0] },
    //     data: {
    //       newLook: this.data.newLook,
    //       futureUserEvents: this.data.futureUserEvents,
    //     },
    //     autoFocus: false,
    //     minHeight: '400px',
    //     maxWidth: '340px',
    //   }
    // );
  }

  async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.creatingEvent = true;
      // this.updateEvent();
      const eventName = this.form.controls.eventName.value;
      if (
        this.form.controls.eventType === undefined ||
        this.form.controls.eventType.value === undefined ||
        this.form.controls.eventType.value === ''
      ) {
        throw new Error('Event type is required');
      }

      if (
        this.form.controls.onlineOrInStore === undefined ||
        this.form.controls.onlineOrInStore.value === undefined ||
        this.form.controls.onlineOrInStore.value === ''
      ) {
        throw new Error('Online or In Store is required');
      }

      const eventType = this.form.controls.eventType.value;

      const eventDate = this.form.controls.eventDate.value;

      const isSingleUser =
        this.form.controls.individualOrGroup.value ===
        IndividualOrGroup.individual;

      const onlineOrInStore = this.form.controls.onlineOrInStore.value;

      const isInStore = onlineOrInStore === OnlineOrInStore.inStore;
      const inStoreInfo: InStoreInfo = {
        isCreatedByDealer: false,
      };

      const looks = this.data.newLook ? [this.data.newLook] : [];

      const newEvent: Event = {
        id: '',
        eventName,
        eventType,
        eventDate,
        isSingleUser,
        members: [], // the current user will be added to members array in api if isSingleUser is true
        admins: [], // the current user will be added to the admins array and set as the organizer in the api
        looks,
        ...(isInStore ? { inStoreInfo } : undefined),
      };
      /// NOTE: a previous version included the inStoreInfo property and set isCreatedByDealer to false,
      /// but the presence of the inStoreInfo property is what indicates that the event is an inStore event,
      /// so the inStoreInfo property should be omitted if the event is not an inStore event.
      // const info: InStoreInfo = {
      //   isCreatedByDealer: false,
      // };
      // this.data.newEvent.inStoreInfo = info;

      const parseResult = EventSchema.safeParse(newEvent);
      if (!parseResult.success) {
        console.log(parseResult.error.errors);
        throw new Error('Event data is invalid');
      }

      const parsedEvent = parseResult.data;

      try {
        const createdEvent =
          await this.ecomSharedModuleEventService.createEvent(parsedEvent);

        // update the selected event in the event service
        try {
          this.eventService.setSelectedEventWithEvent(createdEvent, 'NewEventModalComponent - onSubmit after createEvent');
          if (!this.environment.dealerPortal) {
            this.cartService.clearCartCache();
          }

          if (this.data.redirectToEvent) {
            // This close is for the output of NewEventModal
            this.dialogRef.close({ newEvent: createdEvent });
            // This parentDialogRef is EventModal (which now requires eventId) as the afterClosed() for this needs it to saveLook
            this.data.parentDialogRef?.close({ eventId: createdEvent.id });
            // Route to first step after successful processing without this routeToFirstStep() we will sit on CL
            this.eventService.routeToFirstStep();
          }

          this.creatingEvent = false;
          this.dialogRef.close({ newEvent: createdEvent });
        } catch (error) {
          console.error(
            'Event was successfully created, but there was an error setting the selected event',
            error
          );
        }

        // if (!this.environment.dealerPortal) {
        //   this.cartService.clearCartCache();
        // }

        // if (this.data.redirectToEvent) {
        //   this.eventService.routeToFirstStep();
        // }

        // this.creatingEvent = false;
        // this.dialogRef.close({ newEvent: createdEvent });
      } catch (error) {
        console.error('Error creating event', error);
        alert(
          'Something went wrong creating the event.  Please try again or contact Customer Service.'
        );
        this.creatingEvent = false;
        this.dialogRef.close();
        return;
      }
    }
  }
}
