import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { JfwTaxEstimateRequest, JfwTaxEstimateResponse } from 'common-types';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TaxApiService {
  // private TAX_REST_API_SERVER = this.environment.tax_rest_api_server_url;

  private TAX_REST_API_V2_SERVER = this.environment.tax_rest_api_v2_server_url;

  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient
  ) {}

  public async apiEstimateTaxV2(
    requestData: JfwTaxEstimateRequest
  ): Promise<JfwTaxEstimateResponse | undefined> {
    const apiUrl = `${this.TAX_REST_API_V2_SERVER}/estimateTax`;

    try {
      return await firstValueFrom(
        this.httpClient
          .post<JfwTaxEstimateResponse>(apiUrl, requestData, {
            ...requireAppCheck,
          })
          .pipe(catchError(this.handleError))
      );
    } catch (error) {
      console.log('Got an error in apiEstimateTaxV2');
      console.log(error);
      return undefined;
    }
  }

  // public async apiRecordTaxV2(
  //   requestData: JfwTaxRecordRequest
  // ): Promise<JfwTaxRecordResponse | undefined> {
  //   const apiUrl = `${this.TAX_REST_API_V2_SERVER}/recordTax`;

  //   try {
  //     return await firstValueFrom(
  //       this.httpClient
  //         .post<JfwTaxRecordResponse>(apiUrl, requestData, { ...requireAppCheck })
  //         .pipe(catchError(this.handleError))
  //     );
  //   } catch (error) {
  //     console.log('Got an error in apiRecordTaxV2');
  //     console.log(error);
  //     return undefined;
  //   }
  // }

  // public apiEstimateTax(
  //   requestData: AvalaraEstimateRequest
  // ): Observable<AvalaraResponse> {
  //   const apiUrl = `${this.TAX_REST_API_SERVER}/estimateTax`;

  //   return this.httpClient.post<AvalaraResponse>(apiUrl, requestData, { ...requireAppCheck }).pipe(
  //     // tap(x => console.log(`avalara response: ${JSON.stringify(x)}`)),
  //     catchError(this.handleError)
  //   );
  // }

  private handleError(err: HttpErrorResponse): Observable<never> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.message}`;
    }
    console.error(err);
    return throwError(() => errorMessage);
  }

  // public apiEstimateTax(): Promise<number> {
  //   console.log('GETTING TAXES FROM API');
  //   const apiUrl = `${this.TAX_REST_API_SERVER}/estimateTax`;
  //   const data = {
  //     "referenceNumber": "junk 4/10/2023 2",
  //     "customerCode": "bert and ernie",
  //     "purchaseOrderNo": "MyPurchaseOrder",
  //     "amount": "100",
  //     "address": {
  //       "address1": "211 e fairway",
  //       "city": "Belleville",
  //       "state": "il",
  //       "zip": "62223"
  //     }
  //   }

  //   let promise = new Promise<number>((resolve, reject) => {
  //     firstValueFrom(this.httpClient.post(apiUrl, data))
  //       .then(
  //         res => {
  //           console.log("tax result", res)
  //           resolve(Math.round(Math.random()));
  //         }
  //       );
  //   });
  //   return promise;
  // }
}
