import { Inject, Injectable, OnDestroy, inject } from '@angular/core';
import { Auth, User, authState } from '@angular/fire/auth';
import { EventService } from '@jfw-library/ecommerce/core';
import { isEventDateLocked } from 'business-logic';
import {
  Event,
  EventDateValidator,
  EventStep,
  Look,
  Validator,
} from 'common-types';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ValidationService implements OnDestroy {
  nextClicked = false;
  subscription = new Subscription();
  // user: firebase.User | null = null;
  user: User | null = null;
  dealerPortal = this.environment.dealerPortal;

  private auth: Auth = inject(Auth);

  constructor(
    @Inject('environment') private environment: any,
    // private afAuth: AngularFireAuth,
    private eventService: EventService
  ) {
    this.subscription.add(
      this.eventService.nextClicked$.subscribe((nextClicked) => {
        this.nextClicked = nextClicked;
      })
    );

    this.subscription.add(
      // this.afAuth.authState.subscribe((user) => {
      authState(this.auth).subscribe((user) => {
        this.user = user;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public isEventLocked(event: Event): boolean {
    return isEventDateLocked(event);
  }

  public isEventStepValid(
    event: Event,
    route: string | undefined,
    eventSteps?: EventStep[]
  ): Validator {
    const validator: Validator = {
      valid: false,
      msg: '',
    };

    switch (route) {
      case 'add-looks':
        return this.addLooksValid(event);
      case 'add-members':
        return this.addMembersValid(event);
      case 'assign-looks':
        return this.assignLooksValid(event);
      case 'choose-store':
        return this.chooseStoreValidEcom(event);
      case 'select-look':
        return this.selectLookValidEcom(event);
      case 'share-event':
        return this.shareEventValidEcom(event);
      // case 'transfer-event':
      //   return this.transferEventValidate(event);
      case 'review-event':
        return eventSteps
          ? this.reviewEventValid(event, eventSteps)
          : validator;
      case 'wearers-info':
        return this.wearersInfoValid(event);
      default:
        return validator;
    }
  }

  public isEventDateLocked(
    event: Event,
    route: string | undefined
  ): EventDateValidator {
    const eventDateValidator: EventDateValidator = {
      valid: true,
      msg: '',
    };

    switch (route) {
      case 'select-look':
      case 'review-event':
      case 'track-progress':
        return this.eventDateLockValidate(event);
      default:
        return eventDateValidator;
    }
  }

  private addLooksValid(event: Event): Validator {
    if (this.dealerPortal) {
      return this.addLooksValidDealer(event);
    } else {
      return this.addLooksValidEcom(event);
    }
  }

  private addLooksValidEcom(event: Event): Validator {
    const validator: Validator = {
      valid: false,
      msg: '',
    };
    if (event.looks.length > 0) {
      validator.msg = '';
      validator.valid = true;
      return validator;
    }
    // if (event.inStoreInfo) {
    //   validator.valid = true;
    //   return validator;
    // }
    validator.msg = 'PLEASE ADD A LOOK';
    return validator;
  }

  private addLooksValidDealer(event: Event): Validator {
    const validator: Validator = {
      valid: false,
      msg: '',
    };

    if (this.user) {
      validator.valid = true;
      return validator;
    }

    if (event.looks.length > 0) {
      validator.msg = '';
      validator.valid = true;
      return validator;
    }
    if (event.inStoreInfo) {
      validator.valid = true;
      return validator;
    }
    validator.msg = 'PLEASE ADD A LOOK';
    return validator;
  }

  private addMembersValid(event: Event): Validator {
    if (this.dealerPortal) {
      return this.addMembersValidDealer(event);
    } else {
      return this.addMembersValidEcom(event);
    }
  }

  private addMembersValidEcom(event: Event): Validator {
    let incompleteMembers: Array<number> = [];
    const validator: Validator = {
      valid: true,
      msg: '',
    };

    if (event.members.length === 0) {
      validator.valid = false;
      validator.msg = 'PLEASE ADD A NEW MEMBER';
      return validator;
    }
    event.members.forEach((member, idx) => {
      if (
        !member.email ||
        !member.firstName ||
        !member.lastName ||
        !member.phone ||
        !member.memberRole
      ) {
        incompleteMembers.push(idx);
      }
    });
    if (incompleteMembers.length !== 0) {
      validator.invalidMembers = incompleteMembers;
      validator.valid = false;
      validator.msg = 'PLEASE FILL OUT MISSING MEMBER INFORMATION';
    }
    return validator;
  }

  private addMembersValidDealer(event: Event): Validator {
    const validator: Validator = {
      valid: true,
      msg: '',
    };
    if (this.user && this.user.uid === event.dealerId) {
      validator.valid = true;
      return validator;
    }
    return validator;
  }

  private assignLooksValid(event: Event): Validator {
    if (this.dealerPortal) {
      return this.assignLooksValidDealer(event);
    } else {
      return this.assignLooksValidEcom(event);
    }
  }

  private assignLooksValidEcom(event: Event): Validator {
    const validator: Validator = {
      valid: false,
      msg: '',
    };
    if (!event.members || event.members.length === 0) {
      validator.valid = false;
      validator.msg = 'Event must have members';
      return validator;
    } else {
      validator.valid = true;
      for (let member of event?.members) {
        if (!member.memberLook) {
          validator.valid = false;
          validator.msg = 'Please assign a look for each member';
        }
      }
    }
    if (validator.valid === true && this.nextClicked) {
      this.eventService.nextClicked$.next(false);
    }
    return validator;
  }

  private assignLooksValidDealer(event: Event): Validator {
    const validator: Validator = {
      valid: true,
      msg: '',
    };
    if (this.user && this.user.uid === event.dealerId) {
      validator.valid = true;
      return validator;
    }
    return validator;
  }

  private chooseStoreValidEcom(event: Event): Validator {
    const validator: Validator = {
      valid: false,
      msg: 'Please Select a Retailer',
    };
    if (event.inStoreInfo?.store !== undefined) {
      validator.valid = true;
      validator.msg = '';
    }
    return validator;
  }

  private selectLookValidEcom(event: Event): Validator {
    const validator: Validator = {
      valid: false,
      msg: '',
    };
    if (event.looks.length === 0) {
      validator.msg = 'Please add a look';
      return validator;
    }
    let selectedLook: Look | undefined;
    event.members.forEach((member) => {
      selectedLook = member.memberLook;
    });
    if (selectedLook !== undefined) {
      validator.valid = true;
      validator.msg = '';
    } else {
      validator.msg = 'You must select a look to proceed';
    }
    if (validator.valid === true && this.nextClicked) {
      this.eventService.nextClicked$.next(false);
    }
    return validator;
  }

  private eventDateLockValidate(event: Event): EventDateValidator {
    const validator: EventDateValidator = {
      valid: false,
      msg: '',
    };
    if (this.isEventLocked(event)) {
      validator.valid = false;
      validator.msg =
        'Please contact Customer Service at 866-783-5767 to make any changes to your order or event.';
    } else {
      validator.valid = true;
    }
    return validator;
  }

  private shareEventValidEcom(event: Event): Validator {
    const validator: Validator = {
      valid: false,
      msg: '',
    };
    const shareEventDetails = event.inStoreInfo?.shareEventDetails;
    if (shareEventDetails) {
      if (
        shareEventDetails.firstName === undefined ||
        shareEventDetails.lastName === undefined ||
        shareEventDetails.email === undefined ||
        shareEventDetails.phone === undefined
        // shareEventDetails.eventSize === undefined
      ) {
        validator.valid = false;
        validator.msg = 'Please fill out all event details';
      } else {
        validator.valid = true;
      }
    } else {
      validator.valid = false;
      validator.msg = 'Need share event details';
    }
    return validator;
  }

  // private transferEventValidate(event: Event): Validator {
  //   const validator: Validator = {
  //     valid: false,
  //     msg: '',
  //   };
  //   if (this.eventService.transferEventError$.getValue()) {
  //     validator.valid = false;
  //     validator.msg = 'There was a problem transferring the event';
  //   } else {
  //     validator.valid = true;
  //     validator.msg = '';
  //   }
  //   return validator;
  // }

  private reviewEventValid(event: Event, eventSteps: EventStep[]): Validator {
    const allStepsValid = eventSteps.every((step) =>
      this.isEventStepValid(event, step.route)
    );
    if (allStepsValid) {
      return {
        valid: true,
        msg: '',
      };
    }

    const firstInvalidStep = this.getFirstInvalidStepIndex(event, eventSteps);
    if (firstInvalidStep === -1) {
      // Should never happen
      return {
        valid: false,
        msg: 'An error occurred while validating the event steps',
      };
    }

    return {
      valid: false,
      msg: `Please complete the "${eventSteps[firstInvalidStep].label}" step`,
    };
  }

  private getFirstInvalidStepIndex(
    event: Event,
    eventSteps: EventStep[]
  ): number {
    for (let i = 0; i < eventSteps.length; i++) {
      if (!this.isEventStepValid(event, eventSteps[i].route)) {
        return i;
      }
    }
    return -1;
  }

  private wearersInfoValid(event: Event): Validator {
    const validator: Validator = {
      valid: false,
      msg: '',
    };

    const wearer = event.members[0];
    const hasFirstName = wearer.firstName && wearer.firstName.length > 0;
    const hasLastName = wearer.lastName && wearer.lastName.length > 0;

    if (hasFirstName && hasLastName) {
      validator.valid = true;
    } else {
      validator.msg = 'First and last name are required';
    }

    return validator;
  }
}
