import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedCommunicationQueueService } from './services/communication-queue/communication-queue.service';
import { UserEcomSettingsService } from './services/user-ecom-settings/user-ecom-settings.service';

@NgModule()
export class SharedServicesModule {
  public static forRoot(
    environment: any
  ): ModuleWithProviders<SharedServicesModule> {
    return {
      ngModule: SharedServicesModule,
      providers: [
        SharedCommunicationQueueService,
        UserEcomSettingsService,
        {
          provide: 'environment',
          useValue: environment,
        },
      ],
    };
  }
}
