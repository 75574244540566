<div *ngIf="eventTimeline$ | async as eventTimeline; else spinner;" class="parent">
  <div class="date">
    <img src="assets/images/icons/review-event/measurements.png" alt=""/><br />
    <p>
      <strong>Get Measured</strong><br />
      by {{ eventTimeline.getMeasuredBy }}
    </p>
  </div>

  <div class="line">
    <hr />
  </div>

  <div class="date">
    <img src="assets/images/icons/review-event/order.png" alt=""/>
    <br />
    <p>
      <strong>Place Order</strong><br />
      by {{ eventTimeline.placeOrdersBy }}
    </p>
  </div>

  <div class="line">
    <hr />
  </div>

  <div class="date">
    <img src="assets/images/icons/review-event/shipped.png" alt=""/>
    <br />
    <p>
      <strong>Order Shipped</strong><br />
      {{ eventTimeline.orderShippedFrom }} to
      {{ eventTimeline.orderShippedTo }}
    </p>
  </div>

  <div class="line">
    <hr />
  </div>

  <div class="date">
    <img src="assets/images/icons/review-event/return.png" alt=""/>
    <br />
    <p>
      <strong>Return Rental</strong><br />
      by {{ eventTimeline.returnRentalsBy }}
    </p>
  </div>
</div>

<ng-template #spinner>
  <div class="event-timeline-loading-spinner-container">
    <!-- <mat-label>Loading Event Timeline...</mat-label> -->
    <mat-progress-bar mode="indeterminate" style="max-width: 72%;"></mat-progress-bar>
  </div>
</ng-template>
