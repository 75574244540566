import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class CanonicalService {
  constructor(@Inject(DOCUMENT) private dom: any) { }

  setCanonicalURL(url?: string) {

    console.log("setting canonical");

    const canURL = url == undefined ? this.dom.URL : url;

    const currentCanon: HTMLLinkElement = this.dom.querySelector("link[rel='canonical']");

    if (currentCanon){
      console.log("found canonical");
      console.log(currentCanon.getAttribute("href"));
      currentCanon.setAttribute('href',canURL);
    }else{
      console.log("didn't find canonical")
      const link: HTMLLinkElement = this.dom.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.dom.head.appendChild(link);
      link.setAttribute('href', canURL);
    }


  }
}
