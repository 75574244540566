<div class="accessory-carousel">
  <app-carousel
    #carousel
    [ariaLabel]="'Carousel of accessories'"
    [vertical]="vertical"
    [useButtons]="useButtons"
    [itemMargin]="'5px'"
  >
    <ng-container
      *ngFor="let mannequinAccessoryImageType of mannequinAccessoryImageTypes"
    >
      <ng-container *ngFor="let style of styles">
        <ng-container
          *ngFor="
            let image of style.mannequinImages!
              | mannequinImage : mannequinAccessoryImageType
          "
        >
          <ng-container *appCarouselItem>
            <img
              class="mannequin-image"
              ngSrc="{{ getImage(image.src) }}"
              [alt]="image.alt"
              class="accessory"
              sizes="(max-width: 959px) 33vw, 15vw"
              fill
            />
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </app-carousel>
</div>
