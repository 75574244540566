import { Component, Input, OnInit } from '@angular/core';
import { storeHoursConverter, storeHoursDisplayType } from 'business-logic';
import { DealerAccountElastic } from 'common-types';

@Component({
  selector: 'app-store-hours',
  templateUrl: './store-hours.component.html',
  styleUrls: ['./store-hours.component.scss'],
})
export class StoreHoursComponent implements OnInit {
  @Input() store: DealerAccountElastic | undefined;
  @Input() justifyContent!: string;
  storeHoursClasses: String[] = [];

  constructor() {}

  ngOnInit(): void {
    if (this.justifyContent) {
      this.storeHoursClasses.push(this.justifyContent);
    }
  }

  setClassName(): string {
    return this.storeHoursClasses.join(' ');
  }

  storeHoursConverter(
    fromTime: string | undefined,
    toTime: string | undefined
  ): string {
    return storeHoursConverter(fromTime, toTime);
  }

  storeHoursDisplayType(
    dayType: string | undefined,
    fromTime: string | undefined,
    toTime: string | undefined
  ): string {
    return storeHoursDisplayType(dayType, fromTime, toTime);
  }
}
